import Utils from "../utils"
import axios from "axios"
import {
  AssetGroupModuleType,
  AssetModuleType,
  BackgroundModuleType,
  Click2MapsModuleType,
  CountdownModuleType,
  CounterModuleType,
  DestinationModuleType,
  GestureModuleType,
  GoogleMapsModuleType,
  ImageSequenceModuleType,
  MatchModuleType,
  MemoryModuleType,
  PanoModuleType,
  PollModuleType,
  PollSliderModuleType,
  PopupModuleType,
  PuzzleModuleType,
  ShakeModuleType,
  SlidebarModuleType,
  SliderModuleType,
  StoryModuleType,
  SurveyModuleType,
  SurveySliderModuleType,
  SwiperGroupModuleType,
  TimerModuleType,
  TypoModuleType,
  VideoControlsModuleType,
  VideoModuleType,
  VideoStoryModuleType,
  WagawinPrivacyModuleType,
  WhatsappModuleType,
  WipeAdBrushModuleType
} from "@/components/designer/module_types/types"
import { getModuleInitEvents } from "@/moduleEvents"
import { Form } from "@/classes/form"
import {
  AD_FORMATS,
  AVAILABLE_GESTURE_MODULES,
  DEVICE_TYPE_DESKTOP,
  PRODUCT_DOOH
} from "@/constants"
import { v4 as uuidv4 } from "uuid"
import {
  AdminRoutes,
  CampaignRoutes,
  CommentRoutes,
  CompanyRoutes,
  DataBindingRoutes,
  DesignRoutes,
  FeatureRoutes,
  FileRoutes,
  GroupRoutes,
  OfferistaRoutes,
  TemplateRoutes,
  UserRoutes
} from "../api/routes"
import { useToast } from "@/composables/useToast"
import { useEventHub } from "@/composables/useEventHub"
import { useApiClient } from "@/composables/useApiClient"
import Language from "../i18n/en"
import { PreviewManager } from "@/classes/previewManager"
import { VariantManager } from "@/classes/variantManager"

export const CONTEXT_AD = "ad"

const $apiClient = useApiClient()
const $toasted = useToast()
const $eventHub = useEventHub()

export default {
  fetchFromRepository ({ commit }, data) {
    commit("changeLoadingState", true)
    const uuid = data.uuid
    return $apiClient.get(DesignRoutes.fetchDesignFromRepository(uuid)).then(_ => {
      commit("changeLoadingState", false)
    })
  },
  // eslint-disable-next-line no-empty-pattern
  fetchAvailableProductsFromBrochure ({ }, { dataBindingId }) {
    return $apiClient.get(OfferistaRoutes.getAvailableProducts(dataBindingId)).then(r => r.data)
  },
  // eslint-disable-next-line no-empty-pattern
  fetchAvailableBannersFromBrochure ({ }, { dataBindingId, detailed = false }) {
    return $apiClient.get(OfferistaRoutes.getAvailableBanners(dataBindingId), { params: { detailed: detailed ? 1 : 0 } }).then(r => r.data)
  },
  // eslint-disable-next-line no-empty-pattern
  fetchAvailableCategoriesFromBrochure ({ }, { dataBindingId }) {
    return $apiClient.get(OfferistaRoutes.getAvailableCategories(dataBindingId)).then(r => r.data)
  },
  // eslint-disable-next-line no-empty-pattern
  setupDoohCreatedWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.updated.video",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyDoohCreatedWsConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.updated.video"
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupNotificationWsConnection ({ }, { userUuid, callback }) {
    Echo.private("App.User." + userUuid).notification(callback)
  },
  // eslint-disable-next-line no-empty-pattern
  setupDesignDataBindingUpdatedWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.updated.databinding",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyDesignDataBindingUpdatedWsConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.updated.databinding"
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupDesignOfferistaDataFetchFailedWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.offerista_data.fetch.failed",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyDesignOfferistaDataFetchFailedWsConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.offerista_data.fetch.failed"
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupOfferistaFetchingWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.offerista_data.fetched",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyOfferistaFetchingWsConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.offerista_data.fetched"
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupDesignCommentUpdatedWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.comments.updated",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyDesignCommentUpdatedWsConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.comments.updated"
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupDesignCommentRemovedWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.comments.deleted",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyDesignCommentRemovedWsConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.comments.deleted"
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupCampaignDesignsProgressWsConnection ({ }, { campaignUuid, callback }) {
    Echo.private(`App.Campaign.${campaignUuid}`).listen(
      ".campaign.updated.progress",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyCampaignDesignsProgressWsConnection ({ }, { campaignUuid }) {
    Echo.private(`App.Campaign.${campaignUuid}`).stopListening(".campaign.duplicated.designs")
  },
  // eslint-disable-next-line no-empty-pattern
  setupCampaignDesignsImportWsConnection ({ }, { companyUuid, callback }) {
    Echo.private(`App.Company.${companyUuid}`).listen(
      ".company.duplicated.campaign",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyCampaignDesignsImportWsConnection ({ }, { campaignUuid }) {
    Echo.private(`App.Company.${campaignUuid}`).stopListening(".company.duplicated.campaign")
  },
  // eslint-disable-next-line no-empty-pattern
  setupPreviewTokenUpdatedWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`)
      .listen(".design.preview.token_updated", callback)
  },
  // eslint-disable-next-line no-empty-pattern
  setupHostedChangeWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`)
      // eslint-disable-next-line n/no-callback-literal
      .listen(".design.host.hosted", e => callback("hosted", e))
      // eslint-disable-next-line n/no-callback-literal
      .listen(".design.host.unhosted", e => callback("unhosted", e))
  },
  // eslint-disable-next-line no-empty-pattern
  setupPreviewUpdatedWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.updated.preview",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupBuildStatusWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.updated.buildstatus",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupProgressWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.updated.progress",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupPushRepoWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.updated.repository",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupVideoWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.updated.video",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupPackageUpdateWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.updated.packages",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupPackageFailedWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.package.failed",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupShowroomConfigUpdateWsConnection ({ }, { companyUuid, callback }) {
    Echo.private(`App.Company.${companyUuid}`).listen(
      ".showroomSettings.updated",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupDesignLockWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.updated.lock",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupDesignScreenshotWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.updated.screenshot",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupDesignSizeIndicatorWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.updated.size",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupDesignDeviceOrientationWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.updated.device_orientation",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupDesignCommentsTypingConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.comments.typing",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  setupDesignCommentsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).listen(
      ".design.comments.new",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyNotificationWsConnection ({ }, { userUuid }) {
    Echo.leave("App.User." + userUuid)
  },
  // eslint-disable-next-line no-empty-pattern
  destroyPreviewTokenUpdatedWsConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.preview.token_updated"
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyPushRepoWsConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.updated.repository"
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyHostedChangeWsConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`)
      .stopListening(".design.host.hosted")
      .stopListening(".design.host.unhosted")
  },
  // eslint-disable-next-line no-empty-pattern
  destroyPreviewUpdatedWsConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.updated.preview"
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyBuildStatusWsConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.updated.buildstatus"
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyProgressWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.updated.progress",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyVideoWsConnection ({ }, { designUuid, callback }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.updated.video",
      callback
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyPackageUpdateWsConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.updated.packages"
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyPackageFailedWsConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.package.failed"
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyShowroomConfigUpdateWsConnection ({ }, { companyUuid }) {
    Echo.private(`App.Company.${companyUuid}`).stopListening(
      ".showroomSettings.updated"
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyDesignLockWsConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.updated.lock"
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyDesignScreenshotWsConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.updated.screenshot"
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyDesignSizeIndicatorWsConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.updated.size"
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyDesignCommentsTypingConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.comments.typing"
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyDesignCommentsConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.comments.new"
    )
  },
  // eslint-disable-next-line no-empty-pattern
  destroyDesignDeviceOrientationWsConnection ({ }, { designUuid }) {
    Echo.private(`App.Design.${designUuid}`).stopListening(
      ".design.updated.device_orientation"
    )
  },
  loadDesignComments ({ commit }, { designUuid }) {
    commit("changeLoadingState", true)
    return $apiClient.get(CommentRoutes.getComments(designUuid)).then(response => {
      commit("setComments", response.data.data)
      commit("changeLoadingState", false)
    })
  },
  // eslint-disable-next-line no-empty-pattern
  addDesignComment ({ }, { designUuid, content, position, comment, author, color }) {
    return $apiClient.post(CommentRoutes.getComments(designUuid), {
      content,
      position,
      reply_to: comment ? comment.uuid : null,
      author,
      color
    })
  },
  // eslint-disable-next-line no-empty-pattern
  sendDesignCommentTyping ({ }, { designUuid }) {
    return $apiClient.post(CommentRoutes.setCommentTyping(designUuid))
  },
  markInitialsOfferistaFetchingData ({ state }) {
    return $apiClient.get(OfferistaRoutes.updateDataFetching(state.activeDesign.uuid), {
      initial_offerista_data_fetching: 0
    })
  },
  markAsResolvedComment ({ commit, state }, { commentUuid }) {
    commit("markAsResolvedComment", commentUuid)
    return $apiClient.patch(CommentRoutes.updateCommentResolve(commentUuid), {
      resolved: 1
    })
  },
  unmarkAsResolvedComment ({ commit, state }, { commentUuid }) {
    commit("unmarkAsResolvedComment", commentUuid)
    return $apiClient.patch(CommentRoutes.updateCommentResolve(commentUuid), {
      resolved: 0
    })
  },
  deleteComment ({ commit, state }, { commentUuid, replyTo }) {
    commit("deleteComment", { commentUuid, replyTo })
    return $apiClient.delete(CommentRoutes.deleteComment(commentUuid))
  },
  editComment ({ commit, state }, { commentUuid, replyTo, newProperties }) {
    commit("editComment", { commentUuid, replyTo, newProperties })
    return $apiClient.patch(CommentRoutes.updateComment(commentUuid), newProperties)
  },
  loadCurrentUser ({ commit }) {
    const URL = UserRoutes.getBasicLoggedUserInformation()
    commit("changeLoadingState", true)
    return axios
      .create() // Skip global interceptors
      .get(URL)
      .then(response => {
        const userData = response.data.data
        commit("setCurrentUser", userData)
        try {
          if (window.Intercom) {
            window.Intercom("boot", {
              app_id: process.env.VUE_APP_INTERCOM_APP_ID,
              user_hash: userData.user_hash,
              user_id: userData.id,
              name: userData.name,
              lastname: userData.lastname,
              email: userData.email,
              account: userData.uuid,
              company: userData?.active_company?.name,
              role: userData.role,
              planType: userData.pricing_package,
              dashboard: userData.selected_dashboard,
              country: userData.country,
              language: userData.language
            })
          }
        } catch (e) {
          $toasted.error(Language.intercom.fail)
        }

        commit("changeLoadingState", false)
        return userData
      })
  },
  async detectAdBlock ({ adBlockDetectedCallback } = {}) {
    let isAdblockEnabled = false
    const adtronAssetUrl = "https://lab.adtron.io/upload/ads/803e5984-7989-42c5-b0ff-197cb8277665/1_img2l0961_57599100.png"
    try {
      await fetch(new Request(adtronAssetUrl)).catch(_ => {
        isAdblockEnabled = true
      })
    } catch (e) {
      isAdblockEnabled = true
    } finally {
      if (isAdblockEnabled && adBlockDetectedCallback) {
        adBlockDetectedCallback(isAdblockEnabled)
      }
    }
  },
  loadAvailableFeatures ({ commit }, campaignUuid) {
    const URL = FeatureRoutes.getAvailableFeatures(campaignUuid)
    commit("changeLoadingState", true)
    return axios
      .create() // Skip global interceptors
      .get(URL)
      .then(response => {
        commit("setAvailableFeatures", response.data)
        commit("changeLoadingState", false)
      }).catch(() => {
      })
  },
  loadAllFeatures ({ commit }) {
    const URL = FeatureRoutes.getAllFeatures()
    commit("changeLoadingState", true)
    return $apiClient
      .get(URL)
      .then(response => {
        commit("setAllFeatures", response.data)
        commit("changeLoadingState", false)
      })
  },
  loadObjectFeatures ({ commit }, objectUuid) {
    const URL = `/api/feature/${objectUuid}`
    commit("changeLoadingState", true)
    return $apiClient
      .get(URL)
      .then(response => {
        commit("setAvailableFeatures", response.data)
        commit("changeLoadingState", false)
      })
  },
  loadLatestComments ({ commit }) {
    const URL = CompanyRoutes.getLatestComments()
    commit("changeLoadingState", true)
    return $apiClient.get(URL, { params: { showcase: window.isRunningIntercomTour } }).then(response => {
      commit("setComments", response.data.data)
      commit("changeLoadingState", false)
    })
  },
  loadRecentCampaigns ({ commit }, { performCommit = true } = {}) {
    const URL = CompanyRoutes.getRecentCampaign()
    commit("changeLoadingState", true)
    return $apiClient.get(URL, { params: { showcase: window.isRunningIntercomTour } }).then(response => {
      if (performCommit) {
        commit("setCampaigns", response.data)
      }
      commit("changeLoadingState", false)
      return response
    })
  },
  loadCompanyUsers ({ commit }) {
    const URL = CompanyRoutes.getCompanyUsers()
    commit("changeLoadingState", true)
    return $apiClient.get(URL).then(response => {
      commit("setUsers", response.data)
      commit("changeLoadingState", false)
    })
  },
  loadBrands (
    { commit, state },
    params = {
      limit: 100,
      page: 1,
      orderBy: "created_at",
      order: "DESC",
      append: false,
      performCommit: true
    }
  ) {
    const filters = { ...params }
    delete filters.performCommit

    const URL = "/api/company/brand?" + Utils.parseUrlFilter(filters)
    commit("changeLoadingState", true)
    return $apiClient.get(URL).then(response => {
      if (params.performCommit) {
        if (params.append) {
          commit("setBrands", {
            data: [].concat(state.availableBrands, response.data.data)
          })
        } else {
          commit("setBrands", response.data)
        }
      }

      commit("changeLoadingState", false)
      return response
    })
  },
  loadSearchResult ({ commit }, { type = "general", query, limit = 10, sortWay = "DESC", sortBy = "last_edit" }) {
    const URL = `/api/search?term=${encodeURIComponent(query)}&type=${type}&limit=${limit}&sortWay=${sortWay}&sortBy=${sortBy}`
    commit("changeLoadingState", true)
    return $apiClient.get(URL).then(response => {
      commit("setSearchResult", response.data)
      commit("changeLoadingState", false)
    })
  },
  loadNotifications ({ commit }) {
    const URL = UserRoutes.getLoggedInUserNotifications()
    commit("changeLoadingState", true)
    return $apiClient.get(URL).then(response => {
      commit("setNotifications", response.data.data)
      commit("changeLoadingState", false)
    })
  },
  addNotification ({ commit }, notification) {
    commit("addNotification", notification)
  },
  setStatus ({ commit }, { status, packageStatus }) {
    commit("setStatus", { status, packageStatus })
  },
  setDesignProgress ({ commit }, progress) {
    commit("setDesignProgress", progress)
  },
  loadData ({ state, commit, dispatch }, data) {
    const uuid = data.uuid
    const init = data.init || false
    commit("changeLoadingState", true)
    let foundVariant = null
    return $apiClient.get(DesignRoutes.getDesignResource(uuid)).then(response => {
      if (response.data.data.scenes.length === 0) { // Fallback mechanism for scenes if missing somehow
        response.data.data.scenes = [
          {
            hidden: false,
            modules: [],
            name: "Scene 1",
            uuid: uuidv4(),
            is_start_transition: true
          }
        ]
      }
      if (!response.data.data.variants || response.data?.data?.variants?.length === 0) { // Variant fallback mechanism
        foundVariant = {
          uuid: uuidv4(),
          dimensions: [320, 480],
          active: true,
          scenes: response.data.data.scenes,
          name: "S (320x480)",
          deviceOrientationLock: true,
          deviceOrientation: "portrait",
          deviceDimensionsLock: false
        }
        response.data.data.variants = [foundVariant]
      } else {
        const variants = response.data.data.variants
        foundVariant = variants.find(v => v.dimensions.join("x") === response.data.data.baseVariantDimensions?.join("x"))
        if (foundVariant) {
          foundVariant.active = true
        } else {
          foundVariant = variants.find(v => v.dimensions.join("x") === response.data.data.oldBaseVariantDimensions?.join("x")) || variants[0]
          foundVariant.active = true
        }
      }

      commit("setActiveDesign", response.data.data)
      commit("setLockedBy", response.data.data.locked_by)
      commit("setLocked", response.data.data.locked)
      commit("setActiveCampaignId", response.data.data.campaignId)
      commit("setName", response.data.data.name)
      commit("setPaketId", response.data.data.packageId)
      commit("setDesignUuid", response.data.data.uuid)
      if (foundVariant?.scenes.length) {
        commit("setScenes", foundVariant.scenes)
        commit("setActiveScene", foundVariant.scenes[0].uuid) // Default scene
      } else {
        commit("setScenes", response.data.data.scenes)
        commit("setActiveScene", response.data.data.scenes[0].uuid) // Default scene
      }

      dispatch("setEvents", response.data.data.events) // Design events
      commit("setAssets", response.data.data.assets)
      commit("setGuidelines", response.data.data.guidelines)
      commit("setSavedColors", response.data.data.savedColors)
      commit("setAdTags", response.data.data.adtags)
      commit("setActiveTimelineTab", response.data.data.uuid)
      commit("changeLoadingState", false)
      commit("setCurrentScope", null)
      if (init) {
        commit("setSettingsContext", {
          context: CONTEXT_AD,
          object: response.data.data
        })
      }
      commit("saveLastState")
      if (state.currentUser.settings) {
        commit("setTimelineScaleX", state.currentUser.settings?.timelineScaleX || 0.7)
        commit("setTimelineScaleY", state.currentUser.settings?.timelineScaleY || 0.7)
        commit("setTimelineOffset", state.currentUser.settings?.timelineOffset || 0.7)
        commit("setDesignerZoom", state.activeDesign.ad_product === PRODUCT_DOOH ? 0.5 : 1)
      }
      dispatch("loadDesignComments", { designUuid: response.data.data.uuid })

      return response
    })
  },
  loadDesignWithoutSetting ({ commit }, data) {
    const uuid = data.uuid
    const URL = "/api/design/"
    commit("changeLoadingState", true)
    return $apiClient.get(URL + uuid).then(response => {
      commit("changeLoadingState", false)
      return response
    })
  },
  loadShowroomData (
    { commit, dispatch },
    { designIdentifier, token }
  ) {
    const URL = `/api/showroom/${designIdentifier}/data?token=${token}`
    commit("changeLoadingState", true)
    return $apiClient.get(URL).then(response => {
      dispatch("loadDesignComments", { designUuid: response.data.data.design.uuid })
      commit("setActiveDesign", response.data.data.design)
      commit("setActiveCampaign", response.data.data.campaign)
      commit("setActiveCampaignId", response.data.data.campaign.uuid)
      commit("setPermissions", response.data.data.permissions)
      commit("changeLoadingState", false)

      return response.data.data
    })
  },
  setAdTagData ({ state, commit }, data) {
    commit("setActiveDesign", data)
    if (data.creation_type === "adtron") {
      const variant = data.variants[0]
      commit("setScenes", variant.scenes)
      if (variant.scenes[0]) {
        commit("setActiveScene", variant.scenes[0].uuid) // Default scene
        commit("setSceneModules", { modules: variant.scenes[0].modules, scene: variant.scenes[0] }) // Default scene modules
      }
    }
    commit("setAdTags", data.adtags)
    commit("setCurrentScope", null)
  },
  changeLoadingState ({ commit }, loadingState) {
    commit("changeLoadingState", loadingState)
  },
  designTakeover ({ dispatch, state }, { uuid }) {
    const URL = DesignRoutes.takeoverDesign(uuid)
    state.lockedBy = state.currentUser // temporarily - will be overwritten on next call
    return $apiClient.get(URL).then(_ => {
      dispatch("loadData", {
        uuid,
        init: false
      })
    })
  },
  lockHeartbeat ({ state, commit }, { uuid }) {
    const URL = DesignRoutes.setDesignHeartBeat(uuid)
    return $apiClient.get(URL).then(response => {
      const { locked, locked_by: lockedBy } = response.data
      if (!Utils.deepEqual(lockedBy, state.lockedBy)) {
        commit("setLockedBy", lockedBy)
        commit("setLocked", locked)
      }

      return response
    })
  },
  setLockedData ({ commit }, data) {
    commit("setLockedBy", data.locked_by)
    commit("setLocked", data.locked)
  },
  loadAssets ({ commit }, { uuid }) {
    commit("changeLoadingState", true)
    return $apiClient.get(DesignRoutes.getDesignResource(uuid)).then(response => {
      commit("setAssets", response.data.data.assets)
      commit("changeLoadingState", false)
    })
  },
  loadSettings ({ commit }, data) {
    const uuid = data.uuid
    const init = data.init || false
    commit("changeLoadingState", true)
    return $apiClient.get(DesignRoutes.getDesignResource(uuid)).then(response => {
      if (init) {
        commit("setSettingsContext", {
          context: CONTEXT_AD,
          object: response.data.data
        })
      }

      commit("changeLoadingState", false)
    })
  },
  loadCampaign ({ commit }, id, performCommit = true) {
    const URL = CampaignRoutes.getSingleCampaignResource(id)
    commit("changeLoadingState", true)
    return $apiClient.get(URL, { params: { showcase: window.isRunningIntercomTour } }).then(response => {
      if (performCommit) {
        commit("setActiveCampaign", response.data.data)
      }
      commit("changeLoadingState", false)

      return response.data.data
    })
  },
  loadPublicCampaign ({ commit }, { uuid, token }) {
    const URL = CampaignRoutes.getPublicCampaignResources(uuid)
    commit("changeLoadingState", true)
    return $apiClient.get(URL, {
      params: {
        token
      }
    }).then(response => {
      commit("setActiveCampaign", response.data.data)
      commit("changeLoadingState", false)

      return response
    })
  },
  pinCampaign ({ commit }, { id, pin }) {
    const URL = CampaignRoutes.updateCampaignPin(id)
    commit("changeLoadingState", true)
    return $apiClient
      .patch(URL, {
        pin
      })
      .then(response => {
        commit("changeLoadingState", false)
        return response
      })
  },
  loadCampaigns ({ commit, state }, params = {}) {
    const URL = CampaignRoutes.getCampaignResources()
    commit("changeLoadingState", true)
    return $apiClient.get(URL, { params: { showcase: window.isRunningIntercomTour, ...params } }).then(response => {
      if (params.concat === true) {
        const campaigns = [].concat(state.campaigns).concat(response.data.data)
        commit("setCampaigns", { data: campaigns })
      } else {
        commit("setCampaigns", response.data)
      }
      commit("changeLoadingState", false)
      return response
    })
  },
  loadBillables ({ commit, state }, { date, sortBy = "impressions", sortWay = "DESC" }) {
    const URL = "/api/billing"
    commit("changeLoadingState", true)
    return $apiClient.get(URL, { params: { date, sortBy, sortWay } }).then(response => {
      commit("setBillables", response.data.data)
      commit("changeLoadingState", false)
      return response
    })
  },
  // eslint-disable-next-line no-empty-pattern
  fetchCampaigns ({ }, params = {}) {
    const URL = CampaignRoutes.getCampaignResources()
    return $apiClient.get(URL, { params: { showcase: window.isRunningIntercomTour, ...params } }).then(response => {
      return response.data
    })
  },
  // eslint-disable-next-line no-empty-pattern
  areThereAnyCampaignsInTheCurrentCompany ({ }) {
    const URL = CompanyRoutes.checkIfAnyCampaigns()
    return $apiClient.get(URL).then(response => response.data)
  },
  // eslint-disable-next-line no-empty-pattern
  duplicateCampaign ({ }, campaignUuid) {
    const uri = CampaignRoutes.duplicateCampaign(campaignUuid)

    return $apiClient.post(uri).then(response => response.data)
  },
  // eslint-disable-next-line no-empty-pattern
  duplicateDesign ({ }, designUuid) {
    const uri = DesignRoutes.duplicateDesign(designUuid)

    return $apiClient.get(uri).then(response => response)
  },
  // eslint-disable-next-line no-empty-pattern
  archiveDesign ({ }, designUniqueId) {
    const uri = DesignRoutes.archiveDesign(designUniqueId)

    return $apiClient.post(uri).then(response => response)
  },
  // eslint-disable-next-line no-empty-pattern
  unarchiveDesign ({ }, designUniqueId) {
    const uri = DesignRoutes.unarchiveDesign(designUniqueId)

    return $apiClient.post(uri).then(response => response)
  },
  // eslint-disable-next-line no-empty-pattern
  destroyCampaign ({ }, campaignUuid) {
    const uri = CampaignRoutes.deleteCampaign(campaignUuid)

    return $apiClient.delete(uri, { params: { id: campaignUuid } }).then(response => response)
  },
  // eslint-disable-next-line no-empty-pattern
  unarchiveCampaign ({ }, campaignUuid) {
    const uri = CampaignRoutes.unarchiveCampaign(campaignUuid)
    return $apiClient.post(uri).then(response => response)
  },
  // eslint-disable-next-line no-empty-pattern
  archiveCampaign ({ }, campaignUuid) {
    const uri = CampaignRoutes.archiveCampaign(campaignUuid)
    return $apiClient.post(uri).then(response => response)
  },
  // eslint-disable-next-line no-empty-pattern
  fetchCampaign ({ }, uniqueId) {
    const URL = CampaignRoutes.getSingleCampaignResource(uniqueId)
    return $apiClient.get(URL).then(response => {
      return response.data.data
    })
  },
  loadInvoices ({ commit }) {
    const URL = "/api/invoice"
    commit("changeLoadingState", true)
    return $apiClient.get(URL).then(response => {
      commit("setInvoices", response.data)
      commit("changeLoadingState", false)

      return response
    })
  },
  loadHostedCampaigns ({ commit }) {
    const URL = CampaignRoutes.getHostedCampaignsResources()
    commit("changeLoadingState", true)
    return $apiClient.get(URL).then(response => {
      commit("setHostedCampaigns", response.data)
      commit("changeLoadingState", false)

      return response
    })
  },
  loadCompany ({ commit }, id) {
    const URL = CompanyRoutes.getCompanyResource(id)
    commit("changeLoadingState", true)
    return $apiClient.get(URL).then(response => {
      commit("setActiveCompany", response.data.data)
      commit("changeLoadingState", false)

      return response
    })
  },
  loadCompanies (
    { commit, state },
    params = { limit: 40, page: 1, sortBy: "id", sortWay: "DESC", append: false, performCommit: true }
  ) {
    commit("changeLoadingState", true)
    const URL =
      CompanyRoutes.getCompanyList() + "?page=" +
      params.page +
      "&limit=" +
      params.limit +
      "&orderBy=" +
      params.sortBy +
      "&order=" +
      params.sortWay

    return $apiClient.get(URL).then(response => {
      if (params.performCommit) {
        if (params.append) {
          commit("setCompanies", { data: [].concat(state.companies, response.data.data) })
        } else {
          commit("setCompanies", response.data)
        }
      }
      commit("changeLoadingState", false)
      return response
    })
  },
  changeCompany ({ commit, dispatch }, companyId) {
    const URL = CompanyRoutes.selectCompany()
    commit("changeLoadingState", true)
    return $apiClient.get(URL, {
      params: {
        id: companyId
      }
    }).then(response => {
      commit("setCompanyTags", [])
      commit("changeLoadingState", false)
      return response
    })
  },
  loadDataBindingPreview ({ state }, { dataBindingId, text, row }) {
    const URL = DataBindingRoutes.getDataBindingPreview(dataBindingId)
    return $apiClient.get(URL, { params: { text, row } }).then(response => response)
  },
  loadUsers ({ commit }, params) {
    const filters = { ...params }
    if (filters.hasOwnProperty("performCommit")) {
      delete filters.performCommit
    }

    const URL = `${AdminRoutes.getUsersResources()}?${Utils.parseUrlFilter(filters)}`
    commit("changeLoadingState", true)
    return $apiClient.get(URL).then(response => {
      if (params?.performCommit !== false) {
        commit("setUsers", response.data)
      }
      commit("changeLoadingState", false)
      return response
    })
  },
  loadGroups ({ commit }, params = {}) {
    const performCommit = params.performCommit
    delete params.performCommit

    const URL = GroupRoutes.getGroups() + "?" + Utils.parseUrlFilter(params)
    commit("changeLoadingState", true)
    return $apiClient.get(URL).then(response => {
      if (performCommit !== false) {
        commit("setGroups", response.data)
      }
      commit("changeLoadingState", false)
      return response
    })
  },
  loadDesignAssets ({ commit }, designUuid) {
    commit("changeLoadingState", true)
    return $apiClient.get(DesignRoutes.getDesignResource(designUuid)).then(response => {
      commit("setAssets", response.data.data.assets)
      commit("changeLoadingState", false)
      return response
    })
  },
  loadAdTagDesigns (
    { commit, dispatch },
    {
      campaignUuid,
      page = 1,
      limit = 20,
      sortBy = "id",
      sortWay = "DESC",
      token = "",
      full = false,
      performCommit = true,
      filters = null
    }
  ) {
    const params = {
      page,
      limit,
      orderBy: sortBy,
      order: sortWay,
      token,
      showcase: window.isRunningIntercomTour,
      ...(filters && { filters })
    }

    const URL = "/api/adtag/" + campaignUuid + "/design"
    commit("changeLoadingState", true)
    return $apiClient.get(URL, { params }).then(response => {
      if (performCommit) {
        if (page === 1) {
          commit("setDesigns", { data: [] })
        }

        commit("appendDesigns", response.data)

        if (full && response.data.links.next) {
          dispatch("loadAdTagDesigns", {
            campaignUuid,
            page: page + 1,
            limit,
            sortBy,
            sortWay,
            token,
            full,
            performCommit,
            filters
          })
        }
      }
      commit("changeLoadingState", false)
      return response
    })
  },
  loadDesigns (
    { commit },
    { campaignId, page = 1, limit = 20, sortBy = "id", sortWay = "DESC" }
  ) {
    const params = {
      page,
      limit,
      orderBy: sortBy,
      order: sortWay
    }
    const URL = "/api/design/json/" + campaignId
    commit("changeLoadingState", true)
    return $apiClient.get(URL, { params: { showcase: window.isRunningIntercomTour, ...params } }).then(response => {
      commit("setDesigns", response.data)
      commit("changeLoadingState", false)
      return response
    })
  },
  fetchDesigns (
    { commit },
    { campaignId, page = 1, limit = 20, sortBy = "id", sortWay = "DESC", filters = {}, archived = true }
  ) {
    const params = {
      page,
      limit,
      orderBy: sortBy,
      order: sortWay,
      filters,
      archived
    }
    const URL = "/api/design/json/" + campaignId
    return $apiClient.get(URL, { params: { showcase: window.isRunningIntercomTour, ...params } }).then(response => {
      return response
    })
  },
  loadTemplates ({ state, commit }, {
    performCommit = true,
    page = 1,
    append = false,
    limit = 20,
    designFormat = null
  } = {}) {
    page = Math.max(1, page)
    limit = Math.max(1, limit)
    performCommit = Boolean(performCommit)

    const queryParams = new URLSearchParams({ page: page.toString(), limit: limit.toString() })
    if (designFormat) queryParams.set("design_format", designFormat)

    const URL = TemplateRoutes.getTemplates() + "?" + queryParams.toString()

    commit("changeLoadingState", true)
    return $apiClient.get(URL).then(response => {
      if (performCommit) {
        let data = response.data ? response.data.data : []
        if (append) {
          data = state.templates.concat(data)
        }

        commit("setTemplates", data)
      }
      commit("changeLoadingState", false)

      return response
    })
  },
  clearRecoveryState ({ state }) {
    if (state.activeDesign) {
      window.localStorage.removeItem("design_v2_" + state.activeDesign.uuid)
    }
  },
  loadLiveCampaigns ({ commit }) {
    const URL = CampaignRoutes.getCampaignResources()
    return $apiClient.get(URL, { params: { live: 1, showcase: window.isRunningIntercomTour } }).then(response => {
      commit("setLiveCampaigns", response.data)

      return response
    })
  },
  setCurrentTime ({ commit }, currentTime) {
    commit("setCurrentTime", currentTime)
  },
  incrementCurrentTime ({ commit }, incrementStep) {
    commit("incrementCurrentTime", incrementStep)
  },
  setRecordingTime ({ commit }, recordingTime) {
    commit("setRecordingTime", recordingTime)
  },
  setRecordingModule ({ commit }, module) {
    commit("setRecordingModule", module)
  },
  setUserEdit ({ commit }, user) {
    commit("setUserEdit", user)
  },
  setGroupEdit ({ commit }, group) {
    commit("setGroupEdit", group)
  },
  manageEvent ({ state, commit }, eventUuid) {
    const event = state.events.find(e => e.uuid === eventUuid)
    if (event?.target) {
      $eventHub.$emit("open-interactions-tab")
      setTimeout(() => {
        // This action is overridden by losing scope of Moveable object
        commit("setPreviewBulk", {
          uuid: event.target,
          newProperties: {
            active: true
          }
        })
      }, 0)
    }
  },
  setActiveDesign ({ commit }, design) {
    commit("setActiveDesign", design)
  },
  setActiveInvoice ({ commit }, invoice) {
    commit("setActiveInvoice", invoice)
  },
  setActiveCampaign ({ commit }, campaign) {
    commit("setActiveCampaign", campaign)
  },
  setActiveCampaignId ({ commit }, campaignId) {
    commit("setActiveCampaignId", campaignId)
  },
  setSettingsContext ({ commit }, data) {
    commit("setSettingsContext", data)
  },
  setDraggedAsset ({ commit }, data) {
    commit("setDraggedAsset", data)
  },
  setActiveTemplateId ({ commit }, templateId) {
    commit("setActiveTemplateId", templateId)
  },
  renameDesign (store, { uuid, name }) {
    return $apiClient.put(DesignRoutes.updateDesignDetails(uuid), {
      name
    })
  },
  host (store, { uuid, tag }) {
    return $apiClient.post(DesignRoutes.hostDesign(uuid), {
      tag
    })
  },
  build (store, { uuid, timeSpent }) {
    return $apiClient.get(DesignRoutes.buildDesign(uuid), {
      params: {
        time_spent: timeSpent
      }
    })
  },
  push (store, { uuid }) {
    try {
      if (this?._vm?.$intercom) {
        this?._vm?.$intercom.trackEvent("User pushed new ad to repository")
      }
    } catch (e) {
      $toasted.error(Language.intercom.fail)
    }

    return $apiClient.get(DesignRoutes.pushDesignToRepository(uuid))
  },
  convertExternal (store, { uniqueId }) {
    try {
      if (this?._vm?.$intercom) {
        this?._vm?.$intercom.trackEvent("User converted ad to external")
      }
    } catch (e) {
      $toasted.error(Language.intercom.fail)
    }

    return $apiClient.get(DesignRoutes.convertAdToExternal(uniqueId))
  },
  pack (store, { uuid, job, externalId }) {
    return $apiClient.post(
      "/api/design/" + uuid + "/pack/" + job + "?externalId=" + externalId
    )
  },
  queueDooh (store, { uuid, fps, duration }) {
    return $apiClient.post(
      "/api/design/" + uuid + "/dooh", { fps, duration }
    )
  },
  importDesign (store, { campaignUniqueId, designUniqueId, apiUrl, email, password }) {
    return $apiClient.post(
      CampaignRoutes.importDesign(campaignUniqueId),
      {
        design: designUniqueId,
        api: apiUrl,
        email,
        password
      }
    )
  },
  importDesignFromAnotherCompany (store, { campaignUniqueId, designUniqueId }) {
    return $apiClient.post(CampaignRoutes.importDesignFromAnotherCompany(campaignUniqueId, designUniqueId))
  },
  importCampaignDesignsFromAnotherCompany (store, { campaignUniqueId, clonedCampaignUniqueId }) {
    return $apiClient.post(CampaignRoutes.importCampaignDesignsFromAnotherCompany(campaignUniqueId, clonedCampaignUniqueId))
  },
  storeData ({ state, dispatch, commit }, { uuid }) {
    const synchronizedVariants = VariantManager.synchronizeVariants(state.activeDesign.variants, state.assets, state.scenes)
    const variantsWithSynchronizedInitialPreview = PreviewManager.synchronizeInitialPreview(Utils.cloneDeep(synchronizedVariants), state.events, state.activeDesign, 0)

    commit("setVariants", variantsWithSynchronizedInitialPreview)

    return $apiClient.put(DesignRoutes.updateDesign(uuid), {
      uuid,
      name: state.activeDesign.name,
      assets: state.assets,
      packageId: state.packageId,
      scenes: state.activeDesign.variants[0].scenes,
      hooks: [],
      modules: state.modules,
      events: state.events,
      guidelines: state.guidelines,
      savedColors: state.savedColors,
      variants: variantsWithSynchronizedInitialPreview,
      settings: state.settingsObject,
      type: state.activeDesign.format
    })
  },
  createPreset (store, { uuid, type }) {
    return $apiClient.post(TemplateRoutes.createTemplateBasedOnEditor(), {
      uuid,
      preset_type: type,
      assets: store.state.assets,
      variants: store.state.activeDesign.variants,
      scenes: store.state.scenes,
      events: store.state.events,
      guidelines: store.state.guidelines,
      savedColors: store.state.savedColors
    })
  },
  saveAssets (store, { designUuid, assets }) {
    store.commit("setAssets", assets)
    return $apiClient.patch("/api/design/" + designUuid, {
      uuid: designUuid,
      assets
    })
  },
  removeAssets (store, { designUuid, assetsId, assetRemovedCallback }) {
    const assets = store.state.assets.filter(
      row => assetsId.indexOf(row.id) === -1
    )
    store.commit("saveFirstState")
    store.commit("removeAssetsAssociation", assetsId)
    store.commit("setAssets", assets)
    store.commit("saveState", assetsId)
    return $apiClient
      .patch("/api/design/" + designUuid, {
        uuid: designUuid,
        assets
      })
      .then(() => {
        assetRemovedCallback()
      })
  },
  restoreAsset (store, { designId, assetId }) {
    return $apiClient
      .post(FileRoutes.restoreFile(assetId), {
        designId
      })
      .then(() => {
        $eventHub.$emit("reinit-font-manager")
      })
  },
  deleteAsset (store, assetId) {
    return $apiClient
      .delete(FileRoutes.deleteFile(assetId))
      .then(() => {
        $eventHub.$emit("reinit-font-manager")
      })
  },
  removeAsset (store, { designUuid, assetId, assetRemovedCallback }) {
    return store.dispatch("removeAssets", { designUuid, assetsId: [assetId], assetRemovedCallback })
  },
  saveCroppedImage ({ commit, dispatch }, data) {
    return $apiClient.post(FileRoutes.cropFile(), {
      id: data.id,
      designUuid: data.cropImgData.designUuid,
      image: data.data,
      suffixName: data.suffix
    })
  },
  addScene (store, name) {
    store.commit("addScene", name)
  },
  editScene (store, data) {
    store.commit("editScene", data)
  },
  switchScene (store, sceneUuid) {
    store.commit("switchScene", sceneUuid)
  },
  deleteScene (store, sceneUuid) {
    store.commit("deleteScene", sceneUuid)
  },
  pasteModule (store, { module, pasteIntoCurrentScope }) {
    store.commit("duplicateModule", { module, pasteIntoCurrentScope })
  },
  setGestureIcon ({ state, commit }, { type, moduleUuid }) {
    const modules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )
    const gestureModule = modules.find(m => m.uuid === moduleUuid)
    if (gestureModule) {
      let newType = null
      let newAnimation = null

      switch (type) {
        case PanoModuleType:
          newType = "panoHorizontalIcon"
          newAnimation = "tiltHorizontal"
          break
        case SwiperGroupModuleType:
          const isDesktop = state?.activeDesign?.deviceType === DEVICE_TYPE_DESKTOP
          if (isDesktop) {
            newType = "mouseIcon"
            newAnimation = "tap"
          } else {
            newType = "swipeIcon"
          }
          break
        case ShakeModuleType:
          newType = "shakeIcon"
          newAnimation = "shake"
          break
      }

      if (newType) {
        commit("setModuleDataPropertyWithSceneId", {
          uuid: gestureModule.uuid,
          type: "gestureType",
          propertyName: "value",
          newValue: newType
        })
      }

      if (newAnimation) {
        commit("setModuleDataPropertyWithSceneId", {
          uuid: gestureModule.uuid,
          type: "gestureAnimation",
          propertyName: "value",
          newValue: newAnimation
        })
      }
    }
  },
  /**
   * This function should be only used for Destination typo modules. Adds module
   * @param store
   * @param modulesArray
   */
  addModules (store, modulesArray) {
    store.commit("addModules", modulesArray.map(m => parseModule(m, store)))
  },
  /** Actions used by base module and submodules **/
  addModule (store, data) {
    return new Promise(resolve => {
      if (data.skipHistory !== true) {
        store.commit("saveFirstState")
      }
      $eventHub.$emit("open-settings-tab")
      if (data.skipSelection !== true) {
        store.commit("unselectAllModules")
      }
      const modules = Utils.getActiveSceneModules(
        store.state.activeScene,
        store.state.scenes
      )
      const module = parseModule(data, store)
      store.commit("addModule", module)
      if (data.skipSelection !== true) {
        store.commit("changeSelectedModule", module.uuid)
      }

      const moduleEvents = getModuleInitEvents({
        moduleType: module.type,
        productType: store.state?.activeDesign?.ad_product,
        moduleUuid: module.uuid
      })
      store.commit("addEvents", { events: moduleEvents, purgeDraft: false })

      if ([VideoModuleType, VideoStoryModuleType].includes(module.type)) {
        setTimeout(() => {
          const updatedModule = Utils.getAllModules(store.state.scenes).find(m => m.uuid === module.uuid)

          if (updatedModule) {
            store.commit("setCurrentScope", updatedModule.uuid)
            store
              .dispatch("addModule", {
                moduleName: "Video Controls",
                parentModuleId: updatedModule.uuid,
                type: VideoControlsModuleType,
                data: [
                  {
                    uuid: uuidv4(),
                    type: "linkedModule",
                    value: updatedModule.uuid
                  }
                ],
                preview: {
                  active: false,
                  width: parseInt(updatedModule.preview.width),
                  height: 30,
                  percentHeight: Utils.calculateRelativeValue(
                    30,
                    updatedModule.preview.height
                  ),
                  transform: Utils.encodeTransform({
                    translateX: "0px",
                    translateY: String(updatedModule.preview.height - 35 + "px")
                  })
                },
                skipSelection: true,
                skipHistory: true
              })
              .then(() => {
                setTimeout(() => {
                  store.commit("setCurrentScope", updatedModule.parentModuleId)
                  store.commit("changeSelectedModule", updatedModule.uuid)
                  store.commit("activateModule", updatedModule.uuid)
                }, 200)
              })
          }
        }, 300)
      } else if (module.type === PollModuleType) {
        setTimeout(() => {
          const updatedModule = Utils.getAllModules(store.state.scenes).find(m => m.uuid === module.uuid)

          const sliderBar = {
            moduleName: "Slider Bar",
            parentModuleId: updatedModule.uuid,
            type: PollSliderModuleType,
            skipSelection: true,
            skipHistory: true,
            uuid: uuidv4(),
            preview: {
              active: false,
              width: updatedModule.preview.width,
              height: 50,
              percentHeight: Utils.calculateRelativeValue(
                50,
                updatedModule.preview.height
              ),
              transform: Utils.encodeTransform({
                translateX: "0px",
                translateY: String(updatedModule.preview.height - 150 + "px")
              })
            }
          }

          const [typoLeft, typoRight] = Utils.getPollResultsTypoModules({ parentModule: updatedModule })

          if (updatedModule) {
            store.commit("setCurrentScope", updatedModule.uuid)
            store
              .dispatch("addModules", [sliderBar, typoLeft, typoRight])
              .then(() => {
                const groupData = {
                  activeGroupIndex: 0,
                  groups: [[sliderBar, typoLeft, typoRight].map(m => m.uuid)]
                }
                Utils.setModuleDataValue(updatedModule, "group", groupData, store)
                store.commit("setCurrentScope", updatedModule.parentModuleId)
                store.commit("changeSelectedModule", updatedModule.uuid)
                store.commit("activateModule", updatedModule.uuid)
                store.commit("updateModulesZIndex")
              })
          }
        }, 300)
      } else if (module.type === SurveyModuleType) {
        setTimeout(() => {
          const updatedModule = Utils.getAllModules(store.state.scenes).find(m => m.uuid === module.uuid)
          if (updatedModule) {
            const sliderHeight = Utils.clamp(100, 300, updatedModule.preview.height)
            const sliderArea = {
              moduleName: "Slider area",
              parentModuleId: updatedModule.uuid,
              type: SurveySliderModuleType,
              skipSelection: true,
              skipHistory: true,
              uuid: uuidv4(),
              preview: {
                active: false,
                width: updatedModule.preview.width,
                height: sliderHeight,
                percentHeight: Utils.calculateRelativeValue(
                  sliderHeight,
                  updatedModule.preview.height
                ),
                transform: Utils.encodeTransform({
                  translateX: "0px",
                  translateY: String(70 + "px")
                }),
                keepDimensionsRatio: true
              }
            }

            store.commit("setCurrentScope", updatedModule.uuid)
            store
              .dispatch("addModules", [sliderArea])
              .then(() => {
                const groupData = {
                  activeGroupIndex: 0,
                  groups: [[sliderArea].map(m => m.uuid)]
                }
                Utils.setModuleDataValue(updatedModule, "group", groupData, store)
                store.commit("setCurrentScope", updatedModule.parentModuleId)
                store.commit("changeSelectedModule", updatedModule.uuid)
                store.commit("activateModule", updatedModule.uuid)
                store.commit("updateModulesZIndex")
              })
          }
        }, 300)
      } else if (module.type === CountdownModuleType) {
        setTimeout(() => {
          const updatedModule = Utils.getAllModules(store.state.scenes).find(m => m.uuid === module.uuid)
          if (updatedModule) {
            const [days, hours, minutes, seconds] = Utils.getCountdownResultsTypoModules({ parentModule: updatedModule })

            store.commit("setCurrentScope", updatedModule.uuid)
            store
              .dispatch("addModules", [days, hours, minutes, seconds])
              .then(() => {
                const groupData = {
                  activeGroupIndex: 0,
                  groups: [[days.uuid, hours.uuid, minutes.uuid, seconds.uuid]]
                }
                Utils.setModuleDataValue(updatedModule, "group", groupData, store)
                store.commit("setCurrentScope", updatedModule.parentModuleId)
                store.commit("changeSelectedModule", updatedModule.uuid)
                store.commit("activateModule", updatedModule.uuid)
                store.commit("updateModulesZIndex")
              })
          }
        }, 300)
      } else if (module.type === TimerModuleType) {
        setTimeout(() => {
          const updatedModule = Utils.getAllModules(store.state.scenes).find(m => m.uuid === module.uuid)
          if (updatedModule) {
            const [timer] = Utils.getTimerTypoModules({ parentModule: updatedModule })

            store.commit("setCurrentScope", updatedModule.uuid)
            store
              .dispatch("addModules", [timer])
              .then(() => {
                const groupData = {
                  activeGroupIndex: 0,
                  groups: [[timer.uuid]]
                }
                Utils.setModuleDataValue(updatedModule, "group", groupData, store)
                store.commit("setCurrentScope", updatedModule.parentModuleId)
                store.commit("changeSelectedModule", updatedModule.uuid)
                store.commit("activateModule", updatedModule.uuid)
                store.commit("updateModulesZIndex")
              })
          }
        }, 300)
      }

      const scope = store.state.currentScope
      const isDooh = store.state.activeDesign?.ad_product === PRODUCT_DOOH || store.state.activeDesign?.format === AD_FORMATS.vast_csv_gallery
      const shouldAddGesture = AVAILABLE_GESTURE_MODULES.includes(module.type) && scope === null
      const includesGesture =
        modules.filter(m => m.type === GestureModuleType).length > 0
      if (!isDooh && shouldAddGesture && !includesGesture) {
        store.dispatch("addModule", {
          moduleName: "gesture",
          type: GestureModuleType
        }).then(moduleUuid => {
          store.dispatch("setGestureIcon", { type: module.type, moduleUuid })
            .then(() => {
              store.commit("setCurrentScope", module.parentModuleId)
              store.commit("changeSelectedModule", module.uuid)
              store.commit("activateModule", module.uuid)
              store.commit("updateModulesZIndex")
            })
        })

      }

      if (data.skipHistory !== true) {
        store.commit("saveState")
      }

      resolve(module.uuid)
    })
  },
  addModuleData (store, data) {
    const dataObject = {
      data: {
        name: data.name || "image",
        type: data.type || "image",
        parent: data.moduleUuid || data.moduleId,
        value: data.value,
        uuid: data.uuid || uuidv4()
      },
      index: data.moduleId,
      uuid: data.uuid
    }
    store.commit("addDataToModule", dataObject)
  },
  renameModule (store, data) {
    store.commit("renameModule", data)
  },
  setModuleImage (store, data) {
    store.commit("setModuleImage", data)
  },
  setPreviewDimensions (store, data) {
    const width = data.asset.width
    const height = data.asset.height
    const ratio = width / height
    const modules = Utils.getActiveSceneModules(
      store.state.activeScene,
      store.state.scenes
    )

    // get current width
    if (width && height && ratio) {
      const parentModuleUuid = modules[data.moduleId].parentModuleId
      const parentModule = parentModuleUuid
        ? modules.find(e => e.uuid === parentModuleUuid)
        : null
      const currentWidth = modules[data.moduleId].preview.width

      if (data.assetDimensions) {
        modules[data.moduleId].preview.height = height / 2
        modules[data.moduleId].preview.width = width / 2
      } else {
        modules[data.moduleId].preview.height = currentWidth / ratio
        modules[data.moduleId].preview.width = currentWidth
      }

      if (store.state.activeDesign) {
        const dim = Utils.getSceneDimensions(store.state.activeDesign, store.state.scenes, store.state.activeScene)
        modules[
          data.moduleId
        ].preview.percentHeight = Utils.calculateRelativeValue(
          modules[data.moduleId].preview.height,
          parentModule ? parentModule.preview.height : dim.height
        )
        modules[
          data.moduleId
        ].preview.percentWidth = Utils.calculateRelativeValue(
          modules[data.moduleId].preview.width,
          parentModule ? parentModule.preview.width : dim.width
        )
      }
      const activeScene = Utils.getActiveScene(
        store.state.activeScene,
        store.state.scenes
      )
      store.commit("setSceneModules", { modules, scene: activeScene })
    }
  },
  removeModuleImage (store, data) {
    const modules = Utils.getActiveSceneModules(
      store.state.activeScene,
      store.state.scenes
    )
    const module = Utils.getModuleById(data.dataModuleUuid || data.uuid, modules)

    const moduleDataBackground = Utils.getModuleDataValue(
      module,
      "background",
      ""
    )
    store.commit("setModuleDataPropertyWithSceneId", {
      uuid: module.uuid,
      type: data.type ? data.type : "image",
      propertyName: "value",
      newValue: ""
    })

    store.commit("setModuleDataPropertyWithSceneId", {
      uuid: module.uuid,
      type: data.type ? data.type : "image",
      propertyName: "filePreviewSrc",
      newValue: ""
    })

    if (typeof moduleDataBackground === "string" && ["linear-gradient", "radial-gradient"].some(el => moduleDataBackground.includes(el))) {
      store.commit("setStyleByUuid", {
        uuid: data.styleModuleUuid || module.uuid,
        property: "background-image",
        value: moduleDataBackground
      })
    } else if (
      data.useAsBackground ||
      typeof data.useAsBackground === "undefined"
    ) {
      store.commit("setStyleByUuid", {
        uuid: data.styleModuleUuid || module.uuid,
        property: "background-image",
        value: ""
      })
    }
  },
  setTransform ({ commit }, data) {
    commit("setTransform", data)
  },
  setPreviewBulk ({ commit }, data) {
    commit("setPreviewBulk", data)
  },
  setEvents ({ state, commit }, events) {
    const variants = state.activeDesign.variants.map(v => v.uuid)
    const variantRelatedEvents = events.filter(e => variants.includes(e.design_variant_uuid))
    const nonVariantRelatedEvents = events.filter(e => e.design_variant_uuid === null || typeof (e.design_variant_uuid) === "undefined")
    commit("setEvents", [...variantRelatedEvents, ...nonVariantRelatedEvents])
  },
  setDraftEvent ({ commit }, event) {
    commit("setDraftEvent", event)
  },
  purgeDraftEvent ({ commit, getters }) {
    commit("purgeDraftEvent", getters.getSelectedModule)
  },
  addMultipleEvents ({ commit }, { events, purgeDraft }) {
    commit("addEvents", { events, purgeDraft })
  },
  addEvent ({ commit }, event) {
    return new Promise((resolve) => {
      const uuid = uuidv4()
      event.uuid = uuid
      commit("addEvent", event)
      resolve(uuid)
    })
  },
  editEvent ({ commit }, event) {
    commit("editEvent", event)
  },
  deleteEvent ({ commit, state }, eventUuid) {
    const activeVariant = state.activeDesign.variants.find(v => v.active === true)
    const event = state.events.find(e => {
      if (e.design_variant_uuid) {
        return e.uuid === eventUuid && e.design_variant_uuid === activeVariant.uuid
      }
      return e.uuid === eventUuid
    })

    commit("deleteEvent", event)
  },
  setActiveTimelineBlock ({ commit, dispatch, state }, uuid) {
    if (uuid) {
      // This action is overridden by losing scope of Moveable object
      setTimeout(() => {
        commit("deactiveModules")
        const activeSceneModules = Utils.getActiveSceneModules(state.activeScene, state.scenes)
        const module = activeSceneModules.find(m => m.timeline.find(t => t.uuid === uuid))
        if (module) {
          commit("activateModule", module.uuid)
          commit("changeSelectedModule", module.uuid)
        }
        commit("setActiveTimelineBlock", uuid)
      }, 0)
      $eventHub.$emit("open-settings-tab")
    }

    dispatch("setActiveTimelineBlocks", { uuid, append: false })
  },
  setActiveTimelineBlocks ({ commit, state }, { uuid, append }) {
    commit("setActiveTimelineBlock", uuid)
    if (append) {
      commit("setActiveTimelineBlocks", [...state.activeTimelineBlocks, uuid])
    } else {
      commit("setActiveTimelineBlocks", uuid ? [uuid] : [])
    }
  },
  deleteTimelineTab ({ commit, dispatch, state }, tabUuid) {
    if (tabUuid === state.activeTimelineTab) {
      commit("setActiveTimelineTab", state.activeDesign.uuid)
    }
    dispatch("deleteEvent", tabUuid)
  },
  setCurrentScope ({ commit }, currentScope) {
    commit("setCurrentScope", currentScope)
  },
  setCurrentDirectoryLevel ({ commit }, directoryLevel) {
    commit("setCurrentDirectoryLevel", directoryLevel)
  },
  setCurrentDirectoryStructure ({ commit }, directoryStructure) {
    commit("setCurrentDirectoryStructure", directoryStructure)
  },
  saveDirectoryStructure (store, { designUuid, directoryStructure }) {
    store.commit("setCurrentDirectoryStructure", directoryStructure)
    return $apiClient
      .patch("/api/design/" + designUuid, {
        id: designUuid,
        directoryStructure
      })
      .then(() => {
        $eventHub.$emit("reinit-font-manager")
      })
  },
  setCurrentAssetsTree ({ commit }, data) {
    if (!data.tree) {
      commit("setCurrentAssetsTree", {})
      return
    }

    commit("setCurrentAssetsTree", Utils.getTreeLevel(data.tree, data.level))
  },
  renameFolder ({ dispatch, state }, data) {
    const newName = data.name
    const oldName = data.key
    const oldPath = data.path === "/" ? "" : data.path
    let tree = state.activeDesign.directoryStructure
    let currentLevel = state.currentDirectoryLevel
    if (currentLevel === "/") {
      currentLevel = ""
    }

    const regex = new RegExp(
      "^" + Utils.escapeRegExp(oldPath + oldName + "/") + "(.*)",
      "si"
    )
    tree = tree.map(level => {
      if (level.match(regex) || level === oldPath + oldName) {
        level = level.replace(oldPath + oldName, currentLevel + "/" + newName)
      }

      return level
    })

    // switch assets as well
    let assets = state.assets
    if (assets) {
      assets = assets.map(asset => {
        if (
          asset.directory.match(regex) ||
          asset.directory === oldPath + oldName
        ) {
          asset.directory =
            (asset.directory
              .replace(oldPath + oldName, state.currentDirectoryLevel + "/" + newName + "/")
              .replace(/[\\/]{2,}/ig, "/"))
        }

        if (asset.directory.substring(asset.directory.length - 1, asset.directory.length) === "/" && asset.directory !== "/") {
          asset.directory = asset.directory.substring(0, asset.directory.length - 1)
        }

        return asset
      })

      dispatch("saveAssets", {
        designUuid: state.activeDesign.uuid,
        assets
      })
    }

    dispatch("setCurrentDirectoryStructure", tree)
    dispatch("saveDirectoryStructure", {
      designUuid: state.activeDesign.uuid,
      directoryStructure: tree
    })
  },
  addFolder ({ dispatch, state }, { name, save = true }) {
    const tree = Utils.cloneDeep(state.activeDesign.directoryStructure)
    let currentLevel = Utils.cloneDeep(state.currentDirectoryLevel)
    if (currentLevel === "/") {
      currentLevel = ""
    }
    if (tree.indexOf(currentLevel + "/" + name) === -1) {
      tree.push(currentLevel + "/" + name)

      dispatch("setCurrentDirectoryStructure", tree)

      if (save) {
        dispatch("saveDirectoryStructure", {
          designUuid: state.activeDesign.uuid,
          directoryStructure: tree
        })
      }
    }
  },
  removeFolder ({ commit, dispatch, state }, data) {
    // we do not remove root folder
    if (data.path === "/") {
      return
    }

    const imageSequenceModules = Utils.getAllModules(state.scenes).filter(m => m.type === ImageSequenceModuleType)
    imageSequenceModules.forEach(m => {
      const imageSequenceDataFolderPath = Utils.getModuleDataValue(m, "folderPath", "")
      if (imageSequenceDataFolderPath === data.path) {
        Utils.setModuleDataValue(m, "folderPath", "", { commit })
      }
    })

    const dirs = []
    const regex = new RegExp("^" + Utils.escapeRegExp(data.path + "/"), "i")
    const regexReplace = new RegExp("^" + Utils.escapeRegExp(data.path), "i")
    const directoryStructureClone = Utils.cloneDeep(state.activeDesign.directoryStructure)
    directoryStructureClone.forEach(row => {
      // reassign directories
      if (row.match(regex) || row === data.path) {
        row = row
          .replace(regexReplace, state.currentDirectoryLevel)
          .replace(/[\\/]{2,}/i, "/")
        if (row.length && data.action === "move" && dirs.indexOf(row) === -1) {
          dirs.push(row)
        }
      } else {
        dirs.push(row)
      }
    })

    const assets = Utils.cloneDeep(state.assets)
    const newAssets = []
    const assetsDeleted = []
    if (assets) {
      assets.forEach(asset => {
        if (asset.directory.match(regex) || asset.directory === data.path) {
          asset.directory = asset.directory
            .replace(regexReplace, state.currentDirectoryLevel)
            .replace(/[\\/]{2,}/i, "/")

          if (data.action === "move") {
            newAssets.push(asset)
          } else {
            // Asset deleted - remove it from modules
            assetsDeleted.push(asset)
          }
        } else {
          newAssets.push(asset)
        }
      })
      dispatch("saveAssets", {
        designUuid: state.activeDesign.uuid,
        assets: newAssets
      })
      commit("removeAssetsAssociation", assetsDeleted.map(a => a.id))
    }
    dispatch("setCurrentDirectoryStructure", dirs)
    dispatch("saveDirectoryStructure", {
      designUuid: state.activeDesign.uuid,
      directoryStructure: dirs
    })
  },
  saveLastState ({ commit }) {
    commit("saveLastState")
  },
  toggleUserActivity ({ commit }, { id, active }) {
    commit("changeLoadingState", true)
    return $apiClient.put(AdminRoutes.updateUser(id), { active }).then(resp => {
      commit("changeLoadingState", false)
      return resp
    })
  },
  selectTag ({ commit }, tag) {
    commit("selectTag", tag)
  },
  initDesignTags ({ commit }, { tag, design }) {
    commit("changeLoadingState", true)
    return $apiClient
      .post(DesignRoutes.initDesignTag(design.uuid), { tag })
      .then(resp => {
        commit("changeLoadingState", false)
        return resp
      })
  },
  // eslint-disable-next-line no-empty-pattern
  fetchOfferistaBanners ({ }, bannerDataBindingId) {
    const URL = OfferistaRoutes.fetchOfferistaBanners(bannerDataBindingId)
    return $apiClient.get(URL).then(response => response.data)
  },
  registerBrochure ({ commit }, { brochureId, dataBindingId, designUniqueId, bannerDataBindingId }) {
    commit("changeLoadingState", true)
    return $apiClient
      .post(OfferistaRoutes.queueOfferistaDataFetchingJob(designUniqueId), {
        params: {
          brochureId,
          dataBindingId,
          bannerDataBindingId
        }
      })
      .then(resp => {
        commit("changeLoadingState", false)
        return resp
      })
  },
  loadDataBinding ({ commit, state }, { dataBindingId, column }) {
    if (state.dataBinding.find(d => d.uuid === dataBindingId) && !column) {
      return
    }

    if (!dataBindingId) {
      return
    }

    commit("changeLoadingState", true)

    return $apiClient.get(DataBindingRoutes.getDataBindingResource(dataBindingId), { params: { column } }).then(resp => {
      commit("addDataBinding", resp.data.data)
      commit("changeLoadingState", false)
      return resp.data
    })
  },
  storeDataBinding ({ commit }, { dataBindingUuid, fileId }) {
    commit("changeLoadingState", true)

    return $apiClient
      .post(DataBindingRoutes.createDataBinding(dataBindingUuid), {
        file_id: fileId
      })
      .then(resp => {
        commit("changeLoadingState", false)
        return resp
      })
  },
  fetchCompanyTags ({ commit }) {
    commit("changeLoadingState", true)
    return $apiClient.get(CompanyRoutes.getCompanyTags()).then(response => {
      commit(
        "setCompanyTags",
        response.data.data
          .map(row => row.name)
          .filter((value, index, self) => self.indexOf(value) === index)
      )
      commit("changeLoadingState", false)
    })
  },
  addCompanyTag ({ commit }, tagName) {
    return $apiClient
      .post(CompanyRoutes.getCompanyTags(), { tag_name: tagName })
      .then(resp => {
        commit("addCompanyTag", resp.data.data.tag.name)
        return resp
      })
  },
  addAssetToActiveDesign ({ commit, state }, asset) {
    commit("addAssetToActiveDesign", asset)
  },
  setCropData ({ commit }, data) {
    commit("setCropData", data)
  },
  setDownloadLinks ({ commit }, links) {
    commit("setDownloadLinks", links)
  },
  addColorToPalette ({ commit }, payload) {
    const presetColors = payload.presetColors
    const color = payload.color

    if (color.includes("gradient") === false && presetColors.indexOf(color) === -1) {
      commit("addColorToPalette", color)
    }
  },
  selectDesignVariant ({ commit, state, dispatch }, newVariant) {
    const previousVariant = Utils.cloneDeep(state.activeDesign.variants).find(v => v.active === true)
    commit("synchronizeVariants")
    commit("deactivateAllVariants")
    commit("setActiveTimelineTab", state.activeDesign.uuid)

    const selectedVariant = state.activeDesign.variants.find(
      v =>
        v.dimensions[0] === newVariant.dimensions[0] &&
        v.dimensions[1] === newVariant.dimensions[1]
    )

    if (!selectedVariant) {
      commit("addDesignVariant", newVariant)
      return
    }

    commit("activateVariant", { selectedVariant, previousVariant })
    commit("setScenes", Utils.cloneDeep(selectedVariant.scenes))
  },
  removeDesignVariant ({ commit }, variant) {
    commit("synchronizeScenesBetweenVariants")
    commit("synchronizeModulesBetweenVariants")
    commit("removeVariant", variant)
  },
  synchronizeVariants ({ commit }) {
    commit("saveScenesToActiveVariant")
    commit("synchronizeScenesBetweenVariants")
    commit("synchronizeModulesBetweenVariants")
  },
  setUserWorkspaceSettings ({ commit }, settings) {
    commit("setUserWorkspaceSettings", settings)
  },
  setLoadingStatus ({ commit }, payload) {
    commit("setLoadingStatus", payload)
  },
  saveUserSettings ({ state }) {
    const { currentUser, timelineScaleX, timelineScaleY, timelineOffset, activeDesign } = state
    const designerZoom = activeDesign.ad_product === PRODUCT_DOOH
      ? currentUser.settings.designerZoom
      : (state.designerZoom || 0.5)

    const settings = {
      ...currentUser.settings,
      timelineScaleX,
      timelineScaleY,
      timelineOffset,
      designerZoom
    }

    const form = new Form({ settings }, true)

    return form.post(UserRoutes.updateUserSettings(currentUser.uuid))
  }
}
const parseModule = (data, store) => {
  const modules = Utils.getActiveSceneModules(
    store.state.activeScene,
    store.state.scenes
  )

  const productType = store.state?.activeDesign?.ad_product
  const deviceType = store.state?.activeDesign?.deviceType

  const moduleName = data.moduleName
  const type = data.type
  let maxWidth = document.getElementById("previewer-0").clientWidth
  let maxHeight = document.getElementById("previewer-0").clientHeight

  let width = maxWidth > 0 ? maxWidth : 320
  let height = maxHeight > 0 ? maxHeight : 480
  let dim = null

  if (store.state.activeDesign) {
    dim = Utils.getSceneDimensions(store.state.activeDesign, store.state.scenes, store.state.activeScene)
    width = dim ? dim.width : width
    height = dim ? dim.height : height
  }

  if (!store.state.currentScope) {
    maxWidth = width
    maxHeight = height
  }

  const uuid = data.uuid ? data.uuid : uuidv4()
  let scope = store.state.currentScope
  const currentGroupModule = Utils.cloneDeep(store.getters.getCurrentGroupModule)

  if (data.skipGroupAdd !== true) {
    if (currentGroupModule && currentGroupModule.data[0] !== undefined) {
      const groupData = Object.assign(
        { activeGroupIndex: 0, groups: [[]] },
        currentGroupModule.data[0].value
      )

      groupData.groups[groupData.activeGroupIndex] = [
        uuid,
        ...groupData.groups[groupData.activeGroupIndex]
      ]
      store.commit("setModuleDataPropertyWithSceneId", {
        uuid: currentGroupModule.uuid,
        type: "group",
        propertyName: "value",
        newValue: groupData
      })
    }
  }

  let transformObject = { translateX: "0px", translateY: "0px" }

  if (data.parentModuleId) {
    scope = data.parentModuleId
  } else if (scope && currentGroupModule) {
    width = currentGroupModule.preview.width
    height = currentGroupModule.preview.height

    // Pano case – 3x times bigger than Preview dimensions
    maxWidth = currentGroupModule.preview.width
    maxHeight = currentGroupModule.preview.height
  }

  if (type === GestureModuleType && dim) {
    width = dim.width * 0.3
    height = dim.width * 0.3
    transformObject = {
      translateX: dim.width * 0.35 + "px",
      translateY: dim.height / 2 - dim.width * 0.15 + "px"
    }
  }

  if (type === PanoModuleType) {
    transformObject = {
      translateX: -width + "px",
      translateY: "0px"
    }
    width = width * 3
  }

  if (type === WagawinPrivacyModuleType && dim) {
    width = dim.width
    height = 25
    transformObject = {
      translateX: "0px",
      translateY: dim.height - height + "px"
    }
  } else if (type === SliderModuleType && dim) {
    height = 50
    transformObject = {
      translateX: "0px",
      translateY: String(70 + "px")
    }
  }

  if (type === MemoryModuleType && dim) {
    width = dim.width
    height = dim.height / 2
  }

  let initData = Utils.getModuleInitData(type, productType, deviceType)

  if (currentGroupModule?.type === StoryModuleType && data.type === VideoModuleType) {
    initData.forEach(d => {
      if (d.type === "controlsDisabled") {
        d.value = ["play", "pause", "replay", "full"]
      }
    })
  }

  if (data.data) {
    initData = initData.map((singleData) => {
      if (data.data.find(d => d.type === singleData.type)) {
        return data.data.find(d => d.type === singleData.type)
      }
      return singleData
    })

    initData = initData.concat(data.data.filter(d => initData.map(i => i.type).includes(d.type) === false))
  }

  if (type === TypoModuleType) {
    width = 160
    height = 48

    if (scope) {
      const parentModule = store.getters.getAllModules.find(m => m.uuid === scope)

      if (parentModule?.type === DestinationModuleType) {
        initData.find(d => d.type === "dataBindingId").value = Utils.getModuleDataValue(parentModule, "dataBindingId", null)
      }
    }
  } else if (type === Click2MapsModuleType) {
    if (scope) {
      const parentModule = store.getters.getAllModules.find(m => m.uuid === scope)

      if (parentModule?.type === DestinationModuleType) {
        initData.find(d => d.type === "dataBindingId").value = Utils.getModuleDataValue(parentModule, "dataBindingId", null)
      } else if (parentModule) {
        const rootParentModule = store.getters.getAllModules.find(m => m.uuid === parentModule.parentModuleId)
        if (rootParentModule?.type === DestinationModuleType) {
          initData.find(d => d.type === "dataBindingId").value = Utils.getModuleDataValue(rootParentModule, "dataBindingId", null)
        }
      }
    }
  }

  const minh = 10
  const percentHeight = Utils.calculateRelativeValue(height, maxHeight)

  const module = {
    relative: true,
    keepRatio: false,
    clickable:
      data.clickable ||
      [
        VideoModuleType,
        VideoStoryModuleType,
        AssetGroupModuleType,
        SwiperGroupModuleType,
        PopupModuleType,
        SlidebarModuleType,
        Click2MapsModuleType,
        WhatsappModuleType,
        WagawinPrivacyModuleType,
        GoogleMapsModuleType,
        MatchModuleType
      ].includes(type),
    parentModuleId: scope,
    uuid,
    preview: Object.assign(
      {},
      {
        active: true,
        width,
        height,
        percentWidth: Utils.calculateRelativeValue(width, maxWidth),
        percentHeight,
        draggable: ![PanoModuleType, ShakeModuleType, DestinationModuleType, PollModuleType, SurveyModuleType, CounterModuleType].includes(type),
        resizable: ![PanoModuleType, ShakeModuleType, DestinationModuleType, PollModuleType, SurveyModuleType, CounterModuleType].includes(type),
        rotatable: ![PanoModuleType, ShakeModuleType, VideoControlsModuleType, DestinationModuleType, WipeAdBrushModuleType, PollModuleType, SurveyModuleType, VideoStoryModuleType, CounterModuleType].includes(type),
        transform: Utils.encodeTransform(transformObject),
        transformOrigin: "center center",
        minw: 10,
        minh,
        class: "",
        zIndex: [PanoModuleType].includes(type)
          ? 0
          : modules.length + 1,
        styles: {},
        parallaxX: 0,
        parallaxY: 0,
        parallaxZ: 0,
        parallaxRotateX: 0,
        parallaxRotateY: 0,
        parallaxRotateZ: 0,
        hidden: false,
        locked: false,
        opacity: 1,
        borderRadius: 0,
        keepDimensionsRatio: false,
        flipY: false,
        flipX: false,
        alwaysOnTop: [WagawinPrivacyModuleType].includes(type),
        animationClass: ""
      },
      data.preview ? data.preview : {}
    ),
    timeline: [],
    data: initData,
    styles: Object.assign({
      color: "#000"
    }, data.styles ? data.styles : {})
  }

  if (type !== VideoModuleType && type !== VideoStoryModuleType) {
    module.styles["background-position"] = "center top"
    module.styles["background-size"] = "contain"
  }

  switch (type) {
    case GoogleMapsModuleType:
      module.styles["background-position"] = "center center"
      module.styles["background-size"] = "cover"
      module.styles["background-repeat"] = "no-repeat"
      break
    case AssetModuleType:
    case PollSliderModuleType:
      module.styles["background-position"] = "center center"
      break
    case PuzzleModuleType:
      module.styles["background-position"] = "center center"
      module.styles["background-size"] = "100% 100%"
      module.styles["background-repeat"] = "no-repeat"
      break
    case VideoModuleType:
    case VideoStoryModuleType:
      module.styles["background-color"] = "#000000"
      module.styles["background-position"] = module.styles[
        "object-position"
      ] = "center center"
      module.styles["background-size"] = module.styles["object-fit"] =
        "contain"
      break
    case TypoModuleType:
      module.styles["background-position"] = "center center"
      module.styles["background-size"] = "initial"
      break
    case BackgroundModuleType:
      module.styles["background-color"] = "#000000"
      module.styles["background-image"] = "none"
      module.styles["background-position"] = "center center"
      break
    case GestureModuleType:
      module.styles["background-position"] = "center center"
      break
    case PanoModuleType:
      module.styles["background-position"] = "center center"
      module.styles["background-size"] = "cover"
      module.locked = true
      module.zIndex = -1 // Should be always at the bottom
      break
    case SurveySliderModuleType:
      module.styles["background-position"] = "center center"
      module.zIndex = -1 // Should be always at the bottom
      break
  }

  module.type = type
  const counter =
    modules.filter(e => e.type === type && e.name.includes(moduleName))
      .length + 1
  module.name = `${moduleName}${counter > 1 ? " " + counter : ""}`
  module.htmlId = Utils.generateModuleHtmlId(module)

  if (module.type === PopupModuleType) {
    Utils.setModuleDataValue(module, "popupSelector", module.htmlId)
  }

  return module
}
