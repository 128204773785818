import { undoRedoHistory } from "./plugins/state"
import { markRaw } from "vue"
import Utils from "../utils"
import { AnimationManager } from "@/classes/animationManager"
import { VariantManager } from "@/classes/variantManager"
import {
  AssetGroupModuleType,
  AssetModuleType,
  BackgroundModuleType,
  BasketModuleType,
  DestinationModuleType,
  GestureModuleType,
  ImageSequenceModuleType,
  LightweightSwiperGroupModuleType,
  MatchModuleType,
  PopupModuleType,
  SliderModuleType,
  StoryModuleType,
  SwiperGroupModuleType,
  TypoModuleType,
  VideoModuleType,
  VideoStoryModuleType,
  WipeAdModuleType
} from "@/components/designer/module_types/types"
import { EventManager } from "@/classes/eventManager"
import { v4 as uuidv4 } from "uuid"
import { useEventHub } from "@/composables/useEventHub"
import { addCloseButton } from "@/classes/recipes/presets/ExpandableBanner"
import {
  AVAILABLE_ONLY_ONCE_PER_CREATIVE,
  EDITOR_SCENES_NUMBER_LIMIT,
  EXPANDABLE_BANNER_FORMATS,
  MODULES_WITH_INITIAL_ANIMATION
} from "@/constants"
import { FADE_IN, FADE_OUT } from "@/classes/SceneTransitions"
import { useToast } from "@/composables/useToast"

const $eventHub = useEventHub()
const crypto = require("crypto-browserify")
const $toasted = useToast()

export default {
  setActiveShowroomTheme (state, theme) {
    state.activeShowroomTheme = theme
  },
  openModal (state, {view, options, actions}) {
    state.modal.isOpen = true
    state.modal.view = markRaw(view)
    state.modal.options = options
    state.modal.actions = actions
  },
  closeModal (state) {
    state.modal.isOpen = false
    state.modal.view = {}
    state.modal.options = {}
    state.modal.actions = {}
  },
  duplicateScene (state, scene) {
    if (state.scenes.length >= 10) return
    const newScene = Utils.cloneDeep(scene)
    const assignNewIds = e => {
      e.uuid = uuidv4()
      delete e.id
      return e
    }
    const idx = state.scenes.length
    newScene.name = "Scene " + (parseInt(idx) + 1)
    newScene.uuid = uuidv4()
    delete newScene.id
    const newModuleIds = {}
    const excludedFromCopy = AVAILABLE_ONLY_ONCE_PER_CREATIVE
    const removedModules = []
    newScene.modules = newScene.modules
      .filter(module => {
        if (excludedFromCopy.includes(module.type)) {
          removedModules.push(module.uuid)
          return false
        }

        return true
      })
      .map(e => Utils.cloneDeep(e))
    newScene.modules = newScene.modules.filter(
      module => removedModules.indexOf(module.parentModuleId) === -1
    )

    newScene.modules.forEach(m => {
      newModuleIds[m.uuid] = uuidv4()
    })

    newScene.modules = newScene.modules.map(module => {
      const uuid = newModuleIds[module.uuid]
      module.data = module.data.map(assignNewIds)
      module.timeline = module.timeline.map(assignNewIds)
      module.sceneId = newScene.uuid
      module.uuid = uuid
      module.htmlId = Utils.generateModuleHtmlId(module)
      delete module.id

      module.data = module.data.map(d => {
        switch (d.type) {
          // reset Popup module triggers
          case "popupSelector":
            d.value = module.htmlId
            break
          case "popupCloseTrigger":
            d.value = "#" + module.htmlId
            break
          case "popupOpenTrigger":
            d.value = "#ad-content"
            break
        }
        return d
      })

      if (module.parentModuleId) {
        module.parentModuleId = newModuleIds[module.parentModuleId]
      } else {
        const data = Utils.getModuleDataValue(module, "group", null)
        if (data !== null) {
          data.groups = data.groups.map(group => {
            return group.map(mId => {
              return newModuleIds[mId]
            })
          })
          data.activeGroupIndex = 0
          Utils.setModuleDataValue(module, "group", data, this.$store)
        }
      }
      module.preview.active = false

      return module
    })

    state.scenes.push(newScene)
  },
  updateActiveDesignFields (state, newFields) {
    Object.entries(newFields).forEach(field => {
      state.activeDesign[field[0]] = field[1]
    })
  },
  setFallbackImage (state, value) {
    if (state.activeDesign) {
      state.activeDesign.fallbackImage = value
    }
  },
  setLockScreenImage (state, value) {
    if (state.activeDesign) {
      state.activeDesign.lockScreenImage = value
    }
  },
  setScreenshotImage (state, value) {
    if (state.activeDesign) {
      state.activeDesign.screenshot = value
    }
  },
  duplicateModule (state, { module, newUuid, pasteIntoCurrentScope, isFromLayersPane }) {
    const eventsToAppend = []
    const newModuleIds = {}
    const modulesToAppend = []

    const assignNewIds = (e) => {
      e.uuid = uuidv4()
      delete e.id
      return e
    }

    const resetTriggersInPopup = (m) => {
      if (m.type === PopupModuleType) {
        m.data = m.data.map(d => {
          switch (d.type) {
            case "popupSelector":
              d.value = m.htmlId
              break
            case "popupCloseTrigger":
              d.value = "#" + m.htmlId
              break
            case "popupOpenTrigger":
              d.value = "#ad-content"
              break
          }

          return d
        })
      }
    }

    const updateModuleGroups = (m) => {
      const data = Utils.getModuleDataValue(m, "group", null)
      if (data !== null) {
        data.groups = data.groups.map(group => {
          return group.map(mId => {
            return newModuleIds[mId] ? newModuleIds[mId] : mId
          })
        })
        data.activeGroupIndex = 0
        Utils.setModuleDataValue(m, "group", data)
      }
    }

    const updateModuleEvents = (oldUuid, newUuid) => {
      const events = Utils.cloneDeep(state.events).filter((ev) => ev.target === oldUuid)
      const replacedEventsUuid = {}

      events.forEach((ev) => {
        const newEv = { ...Utils.cloneDeep(ev), uuid: uuidv4(), target: newUuid }
        replacedEventsUuid[ev.uuid] = newEv.uuid

        if (newEv.trigger.includes("with:")) {
          const [trigger, value, rest] = newEv.trigger.split(":")
          newEv.trigger = [trigger, replacedEventsUuid[value], rest].join(":")

          if (newEv.actionTarget === oldUuid) {
            newEv.actionTarget = newUuid
          }
        }

        eventsToAppend.push(newEv)
      })
    }

    const prepareModuleForDuplicate = (m, newUuid) => {
      m.uuid = newUuid
      m.htmlId = Utils.generateModuleHtmlId(m)
      m.data = m.data.map(assignNewIds)
      m.timeline = m.timeline.map(assignNewIds)
      m.sceneId = isFromLayersPane ? module.sceneId : state.activeScene
      delete m.id
      delete m.intId
    }

    const duplicateModule = (module) => {
      const newUuid = uuidv4()
      const oldUuid = module.uuid
      const childModules = Utils.cloneDeep(allModules.filter(m => m.parentModuleId === oldUuid))
      newModuleIds[oldUuid] = newUuid
      prepareModuleForDuplicate(module, newUuid)
      module.parentModuleId = newModuleIds[module.parentModuleId]

      childModules.forEach(childModule => {
        duplicateModule(childModule)
      })

      updateModuleEvents(oldUuid, newUuid)
      updateModuleGroups(module)
      resetTriggersInPopup(module)
      modulesToAppend.push(module)
    }

    // if isFromLayersPane === true - it means that duplicate icon was pressed, therefore the duplicated module should be put next to the original module
    const allModules = Utils.cloneDeep(Utils.getAllModules(state.scenes))
    const newModule = Utils.cloneDeep(module)

    const moduleOldUuid = module.uuid

    // assign to current scene
    newModule.preview.active = !isFromLayersPane
    const pasteSuffix = " (copy)"
    if (newModule.name.length + pasteSuffix.length > 100) {
      newModule.name = newModule.name.substring(0, 100 - pasteSuffix.length) + pasteSuffix
    } else {
      newModule.name += pasteSuffix
    }

    prepareModuleForDuplicate(newModule, newUuid || uuidv4())
    newModule.preview.zIndex = allModules.length
    newModule.parentModuleId = pasteIntoCurrentScope ? state.currentScope : newModule.parentModuleId

    newModuleIds[moduleOldUuid] = newModule.uuid

    allModules.forEach(m => {
      if (m.parentModuleId === moduleOldUuid) {
        duplicateModule(m)
      } else if (m.uuid === newModule.parentModuleId) {
        const group = Utils.cloneDeep(Utils.getModuleDataValue(m, "group", null))
        const moduleFromState = Utils.getModuleById(m.uuid, Utils.getAllModules(state.scenes))
        if (isFromLayersPane && group) {
          const groupIndex = group.groups.findIndex(g => g.includes(moduleOldUuid))
          group.groups[groupIndex !== -1 ? groupIndex : 0].unshift(newModule.uuid)
          Utils.setModuleDataValue(moduleFromState, "group", group, state.store)
        } else if (group) {
          group.groups[newModule.groupIndex ? newModule.groupIndex : group.activeGroupIndex].unshift(newModule.uuid)
          Utils.setModuleDataValue(moduleFromState, "group", group, state.store)
        }
      }
      m.preview.active = false
    })

    updateModuleEvents(moduleOldUuid, newModule.uuid)
    updateModuleGroups(newModule)
    resetTriggersInPopup(newModule)

    Utils.getActiveSceneModules(newModule.sceneId, state.scenes).unshift(newModule)
    Utils.getActiveSceneModules(newModule.sceneId, state.scenes).push(...modulesToAppend)
    state.events.push(...eventsToAppend)

    this.commit("updateModulesZIndex")
  },
  setCurrentScope (state, currentScope) {
    // Deactivate all modules after switching to root scope
    const sceneModules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )
    sceneModules.forEach(module => {
      module.preview.active = false
    })

    if (currentScope === null) {
      this.commit("clearDataBinding")
    } else {
      let module = sceneModules.find(m => m.uuid === currentScope)
      let dataBindingId = Utils.getModuleDataValue(
        module,
        "dataBindingId",
        null
      )
      while (
        module &&
        module.parentModuleId !== null &&
        dataBindingId === null
      ) {
        module = sceneModules.find(m => m.uuid === module.parentModuleId)
        dataBindingId = Utils.getModuleDataValue(module, "dataBindingId", null)
      }
      if (dataBindingId !== null) {
        const columnName = Utils.getModuleDataValue(module, "dealerId", null) || Utils.getModuleDataValue(module, "zipCodeVariable", null) || null
        this.dispatch("loadDataBinding", { dataBindingId, column: columnName })
      }
    }
    state.currentScope = currentScope
  },
  setCurrentTime (state, currentTime) {
    state.currentTime = currentTime
  },
  incrementCurrentTime (state, step) {
    if (!step) step = 1

    state.currentTime += step
  },
  setRecordingTime (state, recordingTime) {
    state.recordingTime = recordingTime
  },
  setRecordingModule (state, data) {
    if (data === null || (data && data.hasOwnProperty("recordingTime"))) {
      state.recordingTime = data === null ? null : data.recordingTime
    }

    if (data === null) {
      state.recordingModule = null
    } else {
      this.commit("unselectAllModules")
      this.commit("activateModule", data.moduleUuid)
      this.commit("changeSelectedModule", data.moduleUuid)
      state.recordingModule = {
        moduleUuid: data.moduleUuid,
        initData: data.initData
      }
    }
  },
  setEditingCustomAnimation (state, editingCustomAnimation) {
    state.editingCustomAnimation = editingCustomAnimation
  },
  setAllModules (state, modules) {
    state.scenes.forEach(scene => {
      scene.modules = modules.filter(m => m.sceneId === scene.uuid)
    })
    this.commit("updateModulesZIndex")
  },
  activateModule (state, moduleUuid) {
    const activeScene = Utils.getActiveScene(state.activeScene, state.scenes)
    activeScene.modules.forEach(module => {
      if (module.uuid === moduleUuid) {
        module.preview.active = true
      }
    })
  },
  setModuleImage (state, data) {
    data = Object.assign(
      {},
      {
        previewDimensions: true,
        assetDimensions: false,
        setBackground: true
      },
      data
    )
    const activeSceneModules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )

    const module = Utils.getModuleById(data.dataModuleUuid || data.uuid, activeSceneModules)
    if (!module) {
      return
    }

    if (data.setBackground) {
      const moduleDataBackground = Utils.getModuleDataValue(
        module,
        "background",
        ""
      )

      if (typeof moduleDataBackground === "string" && ["linear-gradient", "radial-gradient"].some(el => moduleDataBackground.includes(el))) {
        this.commit("setStyleByUuid", {
          uuid: data.styleModuleUuid || module.uuid,
          property: "background-image",
          value: "url('" + data.asset.imagepath + "')," + moduleDataBackground
        })
      } else {
        this.commit("setStyleByUuid", {
          uuid: data.styleModuleUuid || module.uuid,
          property: "background-image",
          value: "url('" + data.asset.imagepath + "')"
        })
      }
    }

    const type = data.dataType || "image"
    Utils.setModuleDataProperty(
      module,
      type,
      "filePreviewSrc",
      data.filetype === "video"
        ? data.asset.imagepath
        : data.asset.relativepath,
      state.store
    )
    Utils.setModuleDataValue(module, type, data.asset.id, state.store)

    if (data.square) {
      const dims = Utils.getSceneDimensions(state.activeDesign, state.scenes, state.activeScene)
      data.asset.width = dims.width
      data.asset.height = dims.width

      this.commit("setStyleByUuid", {
        uuid: data.styleModuleUuid || module.uuid,
        property: "object-fit",
        value: "cover"
      })
      this.commit("setStyleByUuid", {
        uuid: data.styleModuleUuid || module.uuid,
        property: "background-size",
        value: "cover"
      })
      this.commit("setStyleByUuid", {
        uuid: data.styleModuleUuid || module.uuid,
        property: "background-position",
        value: "center center"
      })
    }

    if (data.previewDimensions) {
      this.commit("setPreviewDimensions", data)
    }
  },
  setPreviewDimensions (state, data) {
    const width = data.asset.width
    const height = data.asset.height
    const ratio = width / height
    const modules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )

    // get current width
    if (width && height && ratio) {
      let module = null
      if (data.uuid) {
        module = Utils.getModuleById(data.uuid, modules)
      } else {
        module = modules[data.moduleId]
      }

      const parentModuleUuid = module.parentModuleId
      const parentModule = parentModuleUuid
        ? modules.find(e => e.uuid === parentModuleUuid)
        : null
      const currentWidth = module.preview.width

      if (data.assetDimensions) {
        module.preview.height = height / 2
        module.preview.width = width / 2
      } else {
        module.preview.height = Math.round(currentWidth / ratio)
        module.preview.width = currentWidth
        if ([VideoModuleType, VideoStoryModuleType].includes(module.type) && module.preview.height > module.preview.width) {
          Utils.setModuleDataValue(module, "automaticHeight", 0, state.store)
        }
      }

      const dim = Utils.getSceneDimensions(state.activeDesign, state.scenes, state.activeScene)

      if (state.activeDesign) {
        module.preview.percentHeight = Utils.calculateRelativeValue(
          module.preview.height,
          parentModule ? parentModule.preview.height : dim.height
        )
        module.preview.percentWidth = Utils.calculateRelativeValue(
          module.preview.width,
          parentModule ? parentModule.preview.width : dim.width
        )
      }
    }
  },
  updateModulesZIndex (state) {
    state.scenes.forEach(scene => {
      const zIndexes = Utils.modulesZIndex(scene.modules)
      scene.modules.forEach(m => {
        m.preview.zIndex = zIndexes[m.uuid]
      })
    })
  },
  setSceneModules (state, { modules, scene }) {
    modules = Utils.cloneDeep(modules)
    modules = modules.map(m => {
      m.sceneId = scene.uuid
      return m
    })
    state.scenes.forEach(s => {
      if (s.uuid === scene.uuid) {
        s.modules = modules
      }
    })
    this.commit("updateModulesZIndex")
  },
  setScenes (state, scenes) {
    state.scenes = scenes
  },
  updateSceneProperty (state, { sceneUuid, property, value }) {
    state.scenes.forEach(s => {
      if (s.uuid === sceneUuid) {
        s[property] = value
      }
    })
  },
  // @deprecated: Use editEventProperties mutation
  updateEvent (state, { uuid, data }) {
    state.events = state.events.map(ev => {
      if (ev.uuid === uuid) {
        ev = Object.assign({}, ev, data)
      }

      return ev
    })
  },
  setActiveScene (state, scene) {
    state.activeScene = scene
  },
  setStatus (state, { status, packageStatus }) {
    if (state.activeDesign) {
      state.activeDesign.status = status
      state.activeDesign.packageStatus = packageStatus
    }
  },
  setDesignProgress (state, progress) {
    if (state.activeDesign) {
      state.activeDesign.progress = progress
    }
  },
  setComments (state, data) {
    state.comments = data
  },
  markAsResolvedComment (state, commentUuid) {
    state.comments.forEach(c => {
      if (c.uuid === commentUuid) c.resolved = true
    })
  },
  unmarkAsResolvedComment (state, commentUuid) {
    state.comments.forEach(c => {
      if (c.uuid === commentUuid) c.resolved = false
    })
  },
  setShowResolvedComments (state, showResolvedComments) {
    state.showResolvedComments = showResolvedComments
  },
  deleteComment (state, { commentUuid, replyTo }) {
    if (replyTo) {
      const rootComment = state.comments.find(c => c.uuid === replyTo)
      rootComment.replies = rootComment.replies.filter(c => c.uuid !== commentUuid)
    } else {
      state.comments = state.comments.filter(c => c.uuid !== commentUuid)
    }
  },
  editComment (state, { commentUuid, replyTo, newProperties }) {
    let commentToUpdate = null

    if (replyTo) {
      const rootComment = state.comments.find(c => c.uuid === replyTo)
      commentToUpdate = rootComment.replies.find(c => c.uuid === commentUuid)
    } else {
      commentToUpdate = state.comments.find(c => c.uuid === commentUuid)
    }

    if (commentToUpdate) {
      Object.entries(newProperties).forEach(property => {
        commentToUpdate[property[0]] = property[1]
      })
    }
  },
  setCropData (state, data) {
    state.cropImgData = Object.assign({}, state.cropImgData, data)
  },
  setCurrentlyEditedComment (state, commentUuid) {
    state.currentlyEditedCommentUuid = commentUuid
  },
  deleteTimelineBlock (state, uuid) {
    const activeSceneModules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )
    let timelineBlock = null
    let module = null
    activeSceneModules.forEach(e => {
      if (!timelineBlock) {
        timelineBlock = e.timeline.find(a => a.uuid === uuid)
        module = e
      }
      e.timeline = e.timeline.filter(a => a.uuid !== uuid)
    })

    // If timelineblock is missing it means that it is an event-based animation
    if (!timelineBlock) {
      if (this.activeTimelineBlock === uuid) {
        this.commit("editEventProperties", { eventUuid: uuid, properties: { action: { playTimelineSheet: {} } } })
      } else {
        this.dispatch("deleteEvent", uuid)
      }
      return
    }

    if (state.activeTimelineBlock === uuid) {
      this.dispatch("setActiveTimelineBlock", null)
    }

    let props = AnimationManager.getCalculatedChanges(
      2 * 60 * 1000,
      timelineBlock,
      module
    )

    if (timelineBlock.looped) {
      props = AnimationManager.getCalculatedChanges(
        timelineBlock.start + timelineBlock.duration,
        timelineBlock,
        module
      )
    }

    if (AnimationManager.ANIMATION_OUT_EFFECTS.includes(timelineBlock.type)) {
      props = AnimationManager.getCalculatedChanges(0, timelineBlock, module)
    }

    const newProperties = AnimationManager.getTransformPropsByAnimationBlock(props)

    if (timelineBlock.type === AnimationManager.PROPS_MIXED && newProperties.hasOwnProperty("transform")) {
      newProperties.transform = Utils.encodeTransform(Utils.parseTransform(module.preview.transform), Utils.parseTransform(newProperties.transform))
    }

    this.commit("setPreviewBulk", {
      uuid: module.uuid,
      newProperties
    })

    $eventHub.$emit("recalculate-timeline")
  },
  deleteTimelineBlockBulk (state, timelineBlockUuids) {
    const activeSceneModules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )

    const deleteModuleInitialAnimationTimelineBlock = (module) => {
      if (module.type === GestureModuleType) {
        return $toasted.error("Cannot delete initial animation for gesture module.")
      }

      return Utils.setModuleDataValue(module, "playInitialAnimation", 0, this)

    }
    timelineBlockUuids.forEach(uuid => {
      let timelineBlock = null
      let module = null
      let initialAnimationModule = null
      activeSceneModules.forEach(e => {
        if (!timelineBlock) {
          timelineBlock = e.timeline.find(a => a.uuid === uuid)
          module = e
        }
        if (e.uuid === uuid) {
          initialAnimationModule = e
        }
        e.timeline = e.timeline.filter(a => a.uuid !== uuid)
      })

      // If timelineblock is missing it means that it is an event-based animation
      if (!timelineBlock) {
        if (initialAnimationModule) {
          deleteModuleInitialAnimationTimelineBlock(initialAnimationModule)
        } else if (this.activeTimelineBlock === uuid) {
          this.commit("editEventProperties", { eventUuid: uuid, properties: { action: { playTimelineSheet: {} } } })
        } else {
          this.dispatch("deleteEvent", uuid)
        }
        return
      }

      if (state.activeTimelineBlock === uuid) {
        this.dispatch("setActiveTimelineBlock", null)
      }

      let props = AnimationManager.getCalculatedChanges(
        2 * 60 * 1000,
        timelineBlock,
        module
      )

      if (timelineBlock.looped) {
        props = AnimationManager.getCalculatedChanges(
          timelineBlock.start + timelineBlock.duration,
          timelineBlock,
          module
        )
      }

      if (AnimationManager.ANIMATION_OUT_EFFECTS.includes(timelineBlock.type)) {
        props = AnimationManager.getCalculatedChanges(0, timelineBlock, module)
      }

      const newProperties = AnimationManager.getTransformPropsByAnimationBlock(props)

      if (timelineBlock.type === AnimationManager.PROPS_MIXED && newProperties.hasOwnProperty("transform")) {
        newProperties.transform = Utils.encodeTransform(Utils.parseTransform(module.preview.transform), Utils.parseTransform(newProperties.transform))
      }

      this.commit("setPreviewBulk", {
        uuid: module.uuid,
        newProperties
      })
    })
    $eventHub.$emit("recalculate-timeline")
  },
  updateTimelineBlock (state, { uuid, timelineData }) {
    let activeSceneModules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )
    const events = state.events.filter(e => e.uuid === uuid)
    const moduleSpecific = activeSceneModules.find(e => e.uuid === uuid)

    const updateModuleSpecificTimelineBlock = (moduleSpecific, timelineData) => {
      if (timelineData.hasOwnProperty("duration")) {
        Utils.setModuleDataValue(moduleSpecific, "initialAnimationDuration", Math.floor(timelineData.duration), this)
      }
      if (timelineData.hasOwnProperty("start")) {
        Utils.setModuleDataValue(moduleSpecific, "initialAnimationDelay", Math.floor(timelineData.start), this)
      }
      if (timelineData.hasOwnProperty("looped")) {
        Utils.setModuleDataValue(moduleSpecific, "initialAnimationLoop", timelineData.looped, this)
      }
      if (timelineData.hasOwnProperty("loopDelay")) {
        Utils.setModuleDataValue(moduleSpecific, "initialAnimationLoopDelay", Math.floor(timelineData.loopDelay), this)
      }
    }

    if (moduleSpecific && MODULES_WITH_INITIAL_ANIMATION.includes(moduleSpecific.type)) {
      updateModuleSpecificTimelineBlock(moduleSpecific, timelineData)
    } else if (events.length > 0) {
      const activeVariant = state.activeDesign.variants.find(v => v.active === true)
      const eventWithoutVariant = events.length === 1 && events[0].design_variant_uuid === null // Fallback - if only one event with uuid found & no active variant - set it up
      if (eventWithoutVariant) {
        events[0].design_variant_uuid = activeVariant.uuid
      }
      const event = events.find(e => e.design_variant_uuid === activeVariant.uuid)
      if (event) {
        const action = Object.keys(event.action)[0]
        const eventAnimationData = event.action[action]
        event.action[action] = Object.assign(eventAnimationData, timelineData)
      }
    } else {
      activeSceneModules = activeSceneModules.map(e => {
        e.timeline = e.timeline.map(a => {
          if (a.uuid === uuid) {
            a = Object.assign({}, a, timelineData)
          }
          return a
        })
        return e
      })
      const activeScene = Utils.getActiveScene(state.activeScene, state.scenes)
      this.commit("setSceneModules", {
        modules: activeSceneModules,
        scene: activeScene
      })
    }
    $eventHub.$emit("recalculate-timeline")
  },
  updateTimelineBlocks (state, timelineBlocks) {
    let activeSceneModules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )
    activeSceneModules = activeSceneModules.map(e => {
      e.timeline = e.timeline.map(a => {
        if (Object.keys(timelineBlocks).includes(a.uuid)) {
          a = Object.assign({}, a, timelineBlocks[a.uuid])
        }
        return a
      })
      return e
    })
    const activeScene = Utils.getActiveScene(state.activeScene, state.scenes)
    this.commit("setSceneModules", {
      modules: activeSceneModules,
      scene: activeScene
    })
    $eventHub.$emit("recalculate-timeline")
  },
  addTimelineBlock (state, { uuid, timelineData }) {
    let activeSceneModules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )
    activeSceneModules = activeSceneModules.map(e => {
      if (e.uuid === uuid) {
        e.timeline.push(Object.assign({}, {
          uuid: uuidv4(),
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        }, timelineData))
        if (e.timeline.length === 1) {
          e.preview.transformOrigin = AnimationManager.ANIMATION_PRESETS_ORIGIN[timelineData.type] || "center center"
        }
      }
      return e
    })
    const activeScene = Utils.getActiveScene(state.activeScene, state.scenes)
    this.commit("setSceneModules", {
      modules: activeSceneModules,
      scene: activeScene
    })
    $eventHub.$emit("recalculate-timeline")
  },
  setShiftPressed (state, isPressed) {
    state.shiftPressed = isPressed
  },
  setCtrlPressed (state, isPressed) {
    state.ctrlPressed = isPressed
  },
  setActiveTimelineBlock (state, uuid) {
    state.activeTimelineBlock = uuid
  },
  setActiveTimelineBlocks (state, uuids) {
    state.activeTimelineBlocks = [...new Set(uuids)]
  },
  clearActiveTimelineBlocks (state) {
    state.activeTimelineBlock = null
    state.activeTimelineBlocks = []
  },
  setTimelineFocused (state, isFocused) {
    state.timelineFocused = isFocused
  },
  setSettingsContext (state, data) {
    state.settingsContext = data.context
    state.settingsObject = data.object
    $eventHub.$emit(
      "design-perspective-changed",
      state.settingsObject.deviceSizing
    )
  },
  setDraggedAsset (state, data) {
    state.draggedAsset = data
  },
  setDraggedModule (state, module) {
    state.draggedModule = module
  },
  setDraggedLayer (state, data) {
    state.draggedLayer = data
  },
  setInvoices (state, invoices) {
    state.invoices = invoices.data
  },
  setCampaigns (state, campaigns) {
    // remove duplicates, caused by duplicating campaign & paging
    state.campaigns = Utils.removeDuplicatesByKey(campaigns.data, "uuid")
  },
  updateCampaign (state, { campaignUuid, newProperties }) {
    const campaign = state.campaigns.find(c => c.uuid === campaignUuid)
    if (!campaign) return

    Object.entries(newProperties).forEach(property => {
      campaign[property[0]] = property[1]
    })
  },
  updateDesignSettings (state, newProperties) {
    Object.entries(newProperties).forEach(property => {
      state.settingsObject[property[0]] = property[1]
    })
  },
  setLiveCampaigns (state, campaigns) {
    state.liveCampaigns = campaigns.data
  },
  setHostedCampaigns (state, campaigns) {
    state.hostedCampaigns = campaigns.data
  },
  setCompanies (state, companies) {
    state.companies = companies.data
  },
  setUsers (state, users) {
    state.users = users.data
  },
  setBrands (state, brands) {
    state.availableBrands = brands.data
  },
  setReports (state, reports) {
    state.reports = reports.data
  },
  setGroups (state, groups) {
    state.groups = groups.data
  },
  setDesigns (state, designs) {
    state.designs = designs.data
  },
  appendDesigns (state, designs) {
    state.designs = state.designs.concat(designs.data)
  },
  setPermissions (state, permissions) {
    state.permissions = permissions
  },
  setHostedDesigns (state, designs) {
    state.hostedDesigns = designs.data
  },
  setTemplates (state, templates) {
    state.templates = templates
  },
  removeAssetsAssociation (state, assetIds) {
    const assetModuleDataTypes = [
      "video",
      "image",
      "data",
      "arrowLeft",
      "arrowRight",
      "indicatorImage",
      "indicatorActiveImage",
      "eraser",
      "eraserBg",
      "background",
      "backgroundClean",
      "particle",
      "sliderBackgroundImage",
      "sliderThumbImage"
    ]

    const assetModuleDataTypesUsedAsBackgroundImage = [
      "video",
      "image",
      "background",
      "particle"
    ]
    state.scenes.forEach(scene => {
      scene.modules.forEach(module => {
        const removedData = []
        module.data = module.data.map(data => {
          // @deprecated Old Destination Module content
          /* istanbul ignore next */

          if (
            assetModuleDataTypes.includes(data.type) &&
            assetIds.map(id => id.toString()).includes(data.value ? data.value.toString() : "")
          ) {
            data.value = null
            removedData.push(data.type)
          }
          return data
        })

        if (!module.styles || !module.styles["background-image"]) return

        if (module.type === WipeAdModuleType && removedData.includes("backgroundClean")) {
          if (["linear-gradient", "radial-gradient"].some(el => module.styles["background-image"].includes(el))) {
            module.styles["background-image"] = Utils.getModuleDataValue(module, "background", "")
          } else {
            delete module.styles["background-image"]
          }
        } else if (module.type !== WipeAdModuleType && removedData.some(item => assetModuleDataTypesUsedAsBackgroundImage.includes(item))) {
          if (["linear-gradient", "radial-gradient"].some(el => module.styles["background-image"].includes(el))) {
            module.styles["background-image"] = Utils.getModuleDataValue(module, "background", "")
          } else {
            delete module.styles["background-image"]
          }
        }
      })
    })
  },
  setAssets (state, data) {
    state.assets = data
  },
  setName (state, name) {
    state.name = name
  },
  setPaketId (state, data) {
    state.packageId = data
  },
  setDesignUuid (state, data) {
    state.designUuid = data
  },
  setGroupEdit (state, group) {
    state.activeGroup = group
  },
  setUserEdit (state, user) {
    state.activeUser = user
  },
  setActiveDesign (state, design) {
    design.variants = design.variants.sort((v1, v2) => v1.dimensions[1] - v2.dimensions[1])
    let activeVariant = state.activeDesign ? state.activeDesign.variants.find(v => v.active === true) : null
    if (!activeVariant) {
      activeVariant = design.variants.find(v => v.active === true)
      if (!activeVariant) {
        activeVariant = design.variants.find(v => v.dimensions.join("x") === design?.oldBaseVariantDimensions?.join("x")) || design.variants[0]
      }
    }
    const foundVariant = design.variants.find(v => v.dimensions[0] === activeVariant?.dimensions[0] && v.dimensions[1] === activeVariant?.dimensions[1])
    if (foundVariant) {
      foundVariant.active = true
    }
    state.activeDesign = design
  },
  setCurrentUser (state, user) {
    if (user && (!user.active_company || Array.isArray(user.active_company))) {
      user.active_company = null
    }
    state.currentUser = user
    state.roles = user.roles
  },
  setBillables (state, billables) {
    state.billables = billables
  },
  setAvailableFeatures (state, features) {
    state.availableFeatures = features
  },
  setAllFeatures (state, features) {
    state.allFeatures = features
  },
  setLocked (state, locked) {
    state.locked = locked
    $eventHub.$emit("design-locked", state.locked)
  },
  setLockedBy (state, lockedBy) {
    state.lockedBy = lockedBy
  },
  setActiveInvoice (state, invoice) {
    state.activeInvoice = invoice
  },
  setActiveCampaign (state, campaign) {
    state.activeCampaign = campaign
  },
  setActiveCampaignId (state, campaignId) {
    state.activeCampaignId = campaignId
  },
  setActiveCompany (state, company) {
    state.activeCompany = company
    state.activeCompanyId = company.id
  },
  setActiveTemplateId (state, templateId) {
    state.activeTemplateId = templateId
  },
  setReplaceAssetUuid (state, uuid) {
    state.replaceAssetUuid = uuid
  },
  setTimelinePlaying (state, value) {
    state.timelinePlaying = value
  },
  addModules (state, modules) {
    state.editingCustomAnimation = null
    state.activeTimelineBlock = null
    const activeSceneModules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )
    modules.forEach((module) => {
      const initEvents = getInitEvents(module)
      state.events = state.events.concat(initEvents)
      activeSceneModules.unshift(module)
    })
    const activeScene = Utils.getActiveScene(state.activeScene, state.scenes)
    this.commit("setSceneModules", {
      modules: activeSceneModules,
      scene: activeScene
    })
  },
  addModule (state, module) {
    this.commit("addModules", [module])
  },
  editScene (state, data) {
    state.scenes[
      state.scenes.indexOf(state.scenes.find(s => s.uuid === data.uuid))
    ].name = data.new
  },
  addScene (state, scene) {
    if (state.scenes.length >= EDITOR_SCENES_NUMBER_LIMIT) return
    const uuid = uuidv4()
    state.scenes.push({
      name: scene,
      uuid,
      hidden: false,
      modules: [],
      is_start_transition: true,
      transitionIn: Utils.ucfirst(FADE_IN),
      transitionOut: Utils.ucfirst(FADE_OUT)
    })
    this.commit("switchScene", uuid)

    if (EXPANDABLE_BANNER_FORMATS.includes(state.activeDesign?.format)) {
      window.postMessage({ type: "clear" }, "*")
      const closeButtonUuid = addCloseButton()
      window.postMessage({
        type: "addEvent",
        params: {
          action: {
            changeScene: {
              nextScene: state.scenes[0].uuid
            }
          },
          target: closeButtonUuid,
          trigger: EventManager.TRIGGER_CLICK
        }
      })
      window.postMessage({ type: "run" }, "*")

    }
  },
  deleteScene (state, scene) {
    this.commit("switchScene", state.scenes[0].uuid) // Switch to root scene on delete
    state.events = state.events.filter(e => {
      if (e.action.hasOwnProperty("changeScene")) {
        return e.action.changeScene.nextScene !== scene
      }

      return true
    })
    state.scenes = state.scenes.filter(s => {
      if (s.uuid === scene) {
        s.modules.forEach(m => {
          state.events = state.events.filter(e => {
            return e.target !== m.uuid
          })
        })
      }
      return s.uuid !== scene
    })
    $eventHub.$emit("designer-update-lines")
  },
  unselectAllModules (state) {
    const activeSceneModules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )
    activeSceneModules.forEach(m => {
      if (m.preview.active) {
        m.preview.active = false
      }
    })
  },
  deactiveModules (state) {
    const activeSceneIndex = state.scenes.indexOf(
      state.scenes.find(_scene => {
        return _scene.uuid === state.activeScene
      })
    )

    state.scenes[activeSceneIndex].modules.forEach(module => {
      module.preview.active =
        state.selectedModule &&
        module.uuid === state.selectedModule &&
        [SwiperGroupModuleType, AssetGroupModuleType, StoryModuleType, LightweightSwiperGroupModuleType, MatchModuleType].includes(module.type)
    })
  },
  switchScene (state, scene) {
    const activeSceneIndex = state.scenes.indexOf(
      state.scenes.find(_scene => {
        return _scene.uuid === state.activeScene
      })
    )
    // if switching scenes - disable preview
    state.scenes[activeSceneIndex].modules.forEach(module => {
      module.preview.active = false
    })
    state.activeScene = scene
    this.commit("setCurrentScope", null)
    this.commit("changeSelectedModule", null)
    this.commit("setActiveTimelineTab", state.activeDesign.uuid)

    // TODO: due to bypass in Preview.vue for using :container prop on moveable bound to ref
    $eventHub.$emit("scene-changed", state.activeScene)
  },
  /**
   * User for redo / undo
   */
  saveState (state, deletedAssetsId = []) {
    state.stateId = uuidv4()
    if (state.activeDesign) {
      const unifiedState = Utils.getUnifiedState(state)

      if (deletedAssetsId.length) {
        state.deletedAssetsId = deletedAssetsId
        unifiedState.deletedAssetsId = deletedAssetsId
      }

      try {
        window.localStorage.setItem(
          "design_v2_" + state.activeDesign.uuid,
          JSON.stringify({
            ts: Date.now(),
            ttl: 60 * 60 * 1000,
            state: Utils.getUnifiedState(state)
          })
        )
      } catch (e) {
        console.error(e)
      }
    }
  },
  recoverState (state) {
    const content = JSON.parse(
      window.localStorage.getItem("design_v2_" + state.activeDesign.uuid)
    )
    if (content) {
      undoRedoHistory.replace(Object.assign({}, state, content.state))
    }
  },
  saveFirstState (state) {
    if (!state.firstStateSaved) {
      state.firstStateSaved = true
      this.commit("saveState")
    }
    Utils.clearExpiredLocalStorage()
  },
  saveLastState (state) {
    const watch = (({
      name,
      packageId,
      id,
      scenes,
      events,
      assets
    }) => ({ name, packageId, id, scenes, events, assets }))(state)
    const hash = crypto
      .createHash("md5")
      .update(JSON.stringify(Utils.getUnifiedState(watch)))
      .digest("hex")
    state.lastSavedStateHash = hash

    $eventHub.$emit("recalculate-timeline")
  },
  redo () {
    undoRedoHistory.redo()
  },
  undo () {
    undoRedoHistory.undo()
  },
  addMultipleDataToModule (state, { dataArray, uuid }) {
    const activeSceneModules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )
    activeSceneModules.forEach(m => {
      if (m.uuid === uuid) {
        m.data = m.data.concat(dataArray)
      }
    })
  },
  addDataToModule (state, { data, index, uuid }) {
    const activeSceneModules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )
    if (uuid) {
      activeSceneModules.map(m => {
        if (m.uuid === uuid) {
          m.data.push(data)
        }
        return m
      })
    } else {
      activeSceneModules[index].data.push(data)
    }
  },
  changeSelectedModule (state, uuid) {
    const allModules = Utils.getAllModules(state.scenes)
    const module = allModules.find(m => m.uuid === uuid)

    if (module && module.sceneId !== state.activeScene) {
      state.activeScene = module.sceneId
      $eventHub.$emit("scene-changed")
    }

    state.selectedModule = uuid

    if (state.editingCustomAnimation && !((module && module.timeline.find(t => t.uuid === state.editingCustomAnimation.property.uuid)) || state.events.find(e => e.uuid === state.editingCustomAnimation.property.uuid))) {
      state.editingCustomAnimation = null
    }
    const dataBindingId = Utils.getModuleDataValue(module, "dataBindingId")

    if (dataBindingId) {
      const columnName = Utils.getModuleDataValue(module, "dealerId", null) || Utils.getModuleDataValue(module, "zipCodeVariable", null) || null
      this.dispatch("loadDataBinding", { dataBindingId, column: columnName })
    }
  },
  removeModuleByUuid (state, uuid) {
    const allModules = Utils.getAllModules(state.scenes)
    const childrenModules = Utils.getChildrenModules(
      allModules.find(m => m.uuid === uuid),
      allModules
    )
    const childrenModulesUuids = childrenModules.map(m => m.uuid)
    const module = allModules.find(m => m.uuid === uuid)

    let sceneModules = Utils.cloneDeep(
      Utils.getActiveSceneModules(module.sceneId, state.scenes)
    )

    this.commit("setRecordingModule", null)

    // change active timeline tab to Main when module with interaction is removed
    const moduleEvents = state.events.filter(e => e.target === uuid || e.actionTarget === uuid).map(e => e.uuid)
    if (moduleEvents.includes(state.activeTimelineTab)) {
      this.commit("setActiveTimelineTab", state.activeDesign.uuid)
    }

    this.dispatch(
      "setEvents",
      state.events.filter(
        e => {
          if (e.target === uuid || e.actionTarget === uuid) {
            return false
          }

          return EventManager.ACTIONS_WITH_MODULE_ID.every(action => !e.action.hasOwnProperty(action) || e.action[action].moduleId !== uuid)
        }
      )
    )

    sceneModules = sceneModules.filter(m => {
      const dataBindingId = Utils.getModuleDataValue(m, "dataBindingId", null)
      if (m.uuid === uuid && dataBindingId && m.parentModuleId === null) {
        this.commit("removeDataBinding", dataBindingId)
      }
      return [module.uuid, ...childrenModulesUuids].includes(m.uuid) === false
    })

    sceneModules = sceneModules.map(m => {
      const groupDataValue = Utils.getModuleDataValue(m, "group", null)

      if (groupDataValue) {
        groupDataValue.groups = groupDataValue.groups.map(g => {
          return g.filter(gm => gm !== uuid)
        })
      }

      if (
        m.type === SliderModuleType &&
        [SwiperGroupModuleType, LightweightSwiperGroupModuleType, ImageSequenceModuleType].includes(module.type) &&
        Utils.getModuleDataValue(m, "linkedModule", null) === uuid
      ) {
        Utils.setModuleDataValue(m, "linkedModule", null)
      }

      if (
        m.type === BasketModuleType &&
        [TypoModuleType, BackgroundModuleType, VideoModuleType, AssetModuleType].includes(module.type) &&
        Utils.getModuleDataValue(m, "linkedModules", []).includes(uuid)
      ) {
        const linkedModules = Utils.getModuleDataValue(m, "linkedModules", [])
        const linkedModulesWithoutRemovedModuleUuid = linkedModules.filter(mUuid => mUuid !== uuid)
        Utils.setModuleDataValue(m, "linkedModules", linkedModulesWithoutRemovedModuleUuid)
      }

      if (
        m.type === SwiperGroupModuleType &&
        [SwiperGroupModuleType].includes(module.type) &&
        Utils.getModuleDataValue(m, "linkedGalleries", []).includes(uuid)
      ) {
        const linkedGalleries = Utils.getModuleDataValue(m, "linkedGalleries", [])
        const linkedGalleriesWithoutRemovedModuleUuid = linkedGalleries.filter(mUuid => mUuid !== uuid)
        Utils.setModuleDataValue(m, "linkedGalleries", linkedGalleriesWithoutRemovedModuleUuid)
      }

      return m
    })

    const scene = Utils.getActiveScene(module.sceneId, state.scenes)

    this.commit("setSceneModules", {
      modules: sceneModules,
      scene
    })
  },
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  setLoadingStatus (state, loading) {
    state.loadingStatus = loading
  },
  setSavedColors (state, colors) {
    state.savedColors = colors || []
  },
  addColorToPalette (state, newColor) {
    const colors = state.savedColors
    if (newColor) {
      newColor = newColor.toUpperCase()
      if (
        newColor.length >= 6 &&
        colors.indexOf(newColor) === -1 &&
        state.defaultPalette.indexOf(newColor) === -1
      ) {
        colors.unshift(newColor)
        state.savedColors = colors.slice(0, 20)
      }
    }
  },
  setModuleDataPropertyWithSceneId (state, { uuid, type, propertyName, newValue, sceneId }) {
    if (typeof sceneId === "undefined") {
      sceneId = state.activeScene
    }
    let mod = null
    const activeSceneModules = Utils.getActiveSceneModules(
      sceneId,
      state.scenes
    )
    activeSceneModules.forEach(e => {
      if (e.uuid === uuid) {
        mod = e
        e.data = e.data.map(d => {
          if (d.type === type) {
            d[propertyName] = newValue
          }
          return d
        })
      }
    })
    return mod
  },
  setModuleProperty (state, { uuid, propertyName, newValue }) {
    let mod = null
    const activeSceneModules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )
    activeSceneModules.forEach(e => {
      if (e.uuid === uuid) {
        e[propertyName] = newValue
        mod = e
      }
    })
    return mod
  },
  selectModule (state, { uuid, value }) {
    const activeSceneModules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )
    activeSceneModules.forEach(e => {
      if (e.uuid === uuid) {
        e.preview.active = value || !e.preview.active
      }
    })
  },
  renameModule (state, { uuid, name }) {
    const activeSceneModules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )
    activeSceneModules.forEach(e => {
      if (e.uuid === uuid) {
        const oldHtmlId = e.htmlId

        e.name = name
        e.htmlId = Utils.generateModuleHtmlId(e)

        // Update popup modules htmlIds
        if (activeSceneModules.map(m => m.type).includes(PopupModuleType)) {
          const popups = activeSceneModules.filter(m => m.type === PopupModuleType)

          popups.forEach(p => {
            p.data = p.data.map(d => {
              switch (d.type) {
                case "popupSelector":
                  if (d.value === oldHtmlId) {
                    d.value = e.htmlId
                  }
                  break
                case "popupCloseTrigger":
                  if (d.value === `#${oldHtmlId}`) {
                    d.value = "#" + e.htmlId
                  }
                  break
                case "popupOpenTrigger":
                  if (d.value === `#${oldHtmlId}`) {
                    d.value = "#" + e.htmlId
                  }
                  break
              }
              return d
            })
          })
        }
      }
    })
  },
  setStylesByUuid (state, { uuid, newStyles }) {
    const activeSceneModules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )
    Object.entries(newStyles).forEach((change) => {
      const property = change[0]
      const value = change[1]
      activeSceneModules.forEach(e => {
        if (e.uuid === uuid) {
          e.styles[property] = value
        }
      })
    })
  },
  setStyleByUuid (state, { uuid, property, value }) {
    const activeSceneModules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )

    const module = Utils.getModuleById(uuid, activeSceneModules)
    // Vue does not allow dynamically adding new root-level reactive properties to an already created instance
    if (module.styles.hasOwnProperty(property)) {
      module.styles[property] = value
    } else {
      module.styles[property] = value
      module.styles = { ...module.styles }
    }
  },
  setPreviewBulk (state, { uuid, newProperties, skipDimRecalculation = false }) {
    const allModules = Utils.getAllModules(state.scenes)
    const module = allModules.find(m => m.uuid === uuid)

    if (module) {
      const sceneModules = Utils.getActiveSceneModules(
        module.sceneId,
        state.scenes
      )

      sceneModules.forEach(e => {
        if (e.uuid === uuid) {
          if (!skipDimRecalculation && newProperties.hasOwnProperty("percentHeight") && newProperties.hasOwnProperty("height") === false) {
            newProperties.height = (newProperties.percentHeight / 100) * Utils.getSceneDimensions(state.activeDesign, state.scenes, state.activeScene).height
          }
          if (!skipDimRecalculation && newProperties.hasOwnProperty("percentWidth") && newProperties.hasOwnProperty("width") === false) {
            newProperties.width = (newProperties.percentWidth / 100) * Utils.getSceneDimensions(state.activeDesign, state.scenes, state.activeScene).width
          }
          if (newProperties.hasOwnProperty("active")) {
            if (state.editingCustomAnimation && e.timeline.find(t => t.uuid === state.editingCustomAnimation.property.uuid)) {
              const timelinePropertyUuid = e.timeline.find(t => t.uuid === state.editingCustomAnimation.property.uuid).uuid
              if (timelinePropertyUuid !== uuid) {
                state.editingCustomAnimation = null
              }
            }
          }

          if (Object.entries(newProperties).map(e => e[0]).find(m => ["opacity", "borderRadius", "borderRadiusAnimation", "blurAnimation", "transformAnimation", "opacityAnimation", "widthAnimation", "heightAnimation", "transformOrigin"].includes(m))) {
            const currentProperties = e.preview
            e.preview = Object.assign({}, currentProperties, newProperties)
          } else {
            Object.entries(newProperties).forEach(property => {
              e.preview[property[0]] = property[1]
            })
          }
        }
      })
    } else {
      console.warn(`Cannot modify preview of module with uuid: ${uuid} - module does not exists`)
    }
  },
  setTransform (state, { uuid, newProperties }) {
    const activeSceneModules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )
    activeSceneModules.forEach(e => {
      if (e.uuid === uuid) {
        Utils.updateModuleTransformations(e, newProperties)
      }
    })
  },
  removeStyle (state, { index, property }) {
    const activeSceneModules = Utils.getActiveSceneModules(
      state.activeScene,
      state.scenes
    )
    delete activeSceneModules[index].styles[property]
  },
  setEvents (state, events) {
    state.events = events
  },
  deleteEvent (state, event) {
    if (EventManager.CLICKABLE_TRIGGERS.includes(event.trigger)) {
      const activeSceneModules = Utils.getActiveSceneModules(state.activeScene, state.scenes)
      const targetModule = activeSceneModules.find((m) => m.uuid === event.target)
      const moduleClickableEvents = state.events.filter((e) => e.target === event.target && EventManager.CLICKABLE_TRIGGERS.includes(e.trigger) && e.design_variant_uuid === event.design_variant_uuid)
      if (targetModule && moduleClickableEvents.length <= 1) {
        targetModule.clickable = false
      }
    }

    state.events = state.events.filter(e => {
      if (e.design_variant_uuid !== event.design_variant_uuid) {
        return true
      }

      return (
        e.uuid !== event.uuid &&
        (e.trigger && e.trigger.startsWith("sync:")
          ? e.uuid !== e.trigger.split(":")[1] : true) &&
        (e.trigger && e.trigger.startsWith("with:")
          ? event.uuid !== e.trigger.split(":")[1] : true)
      )
    })
  },
  setDraftEvent (state, draftEvent) {
    state.draftEvent = draftEvent
  },
  purgeDraftEvent (state, selectedModule) {
    const target = selectedModule ? selectedModule.uuid : null
    state.draftEvent = {
      trigger: null,
      target,
      action: {},
      delay: 0,
      conditions: []
    }
  },
  addEvents (state, { events, purgeDraft }) {
    state.events = state.events.concat(events.map((e) => {
      return Object.assign({}, { uuid: uuidv4() }, e)
    }))
    if (purgeDraft !== false) {
      this.dispatch("purgeDraftEvent")
    }
  },
  addEvent (state, event, purgeDraft) {
    let variantUuid = null
    if ([EventManager.ACTION_PLAY_CUSTOM_ANIMATION, EventManager.ACTION_PLAY_ANIMATION, EventManager.ACTION_PLAY_TIMELINE_SHEET].includes(Object.keys(event.action)[0])) {
      const activeVariant = state.activeDesign.variants.find(v => v.active === true)
      variantUuid = activeVariant.uuid
    }
    state.events.push(Object.assign({}, { uuid: event.uuid || uuidv4(), design_variant_uuid: variantUuid }, event))
    if (purgeDraft !== false) {
      this.dispatch("purgeDraftEvent")
    }
  },
  editEvent (state, event) {
    state.events = state.events.map((e) => {
      if (e.uuid === event.uuid) {
        if ([EventManager.ACTION_PLAY_CUSTOM_ANIMATION, EventManager.ACTION_PLAY_ANIMATION, EventManager.ACTION_PLAY_TIMELINE_SHEET].includes(Object.keys(event.action)[0])) {
          const activeVariant = state.activeDesign.variants.find(v => v.active === true)

          if (e.design_variant_uuid === null || e.design_variant_uuid === activeVariant.uuid) {
            event.design_variant_uuid = activeVariant.uuid
          }
        }
        return event
      }
      return e
    })
  },
  editEventProperties (state, { eventUuid, properties }) {
    const event = state.events.find(e => e.uuid === eventUuid)
    Object.entries(properties).forEach(property => {
      event[property[0]] = property[1]
    })
  },
  editEventsProperties (state, events) {
    events.forEach(ev => {
      const event = state.events.find(e => e.uuid === ev.eventUuid)

      if (event) {
        Object.entries(ev.newProperties).forEach(property => {
          event[property[0]] = property[1]
        })
      }
    })
  },
  setAnalyticsDates (state, { start, end }) {
    state.analytics.startDate = start
    state.analytics.endDate = end
  },
  setCurrentAnalytics (state, analyticsData) {
    state.analytics.currentAnalytics = analyticsData
  },
  setCampaignAnalytics (state, analyticsData) {
    state.analytics.campaignAnalytics = analyticsData
  },
  setCurrentDirectoryStructure (state, directoryStructure) {
    state.activeDesign.directoryStructure = directoryStructure
    $eventHub.$emit(
      "directory-structure-changed",
      state.activeDesign.directoryStructure
    )
  },
  setCurrentDirectoryLevel (state, directoryLevel) {
    state.currentDirectoryLevel = directoryLevel
    $eventHub.$emit("directory-changed", state.currentDirectoryLevel)
  },
  setCurrentAssetsTree (state, tree) {
    state.currentAssetsTree = tree
  },
  setBgMarkVisibility (state, status) {
    state.bgMarkVisibility = status
  },
  setGuidelines (state, guidelinesData) {
    state.guidelines = guidelinesData
  },
  setAutoSave (state, status) {
    state.autosave = status
  },
  addPreventedEvent (state, { eventName, check }) {
    state.preventedEvents.push({
      eventName,
      check
    })
  },
  removePreventedEvent (state, { eventName, check }) {
    state.preventedEvents = state.preventedEvents.filter(e => {
      return !(e.eventName === eventName && e.check === check)
    })
  },
  setSearchResult (state, data) {
    state.searchResult = data
  },
  selectFilter (state, data) {
    switch (data.type) {
      case "campaign":
        state.selectedFilters.campaign.push({
          value: data.value,
          name: data.name
        })
        break
      case "tag":
        state.selectedFilters.tag.push({ value: data.value, name: data.name })
        break
      case "brand":
        state.selectedFilters.brand.push({
          value: data.value,
          name: data.name
        })
        break
      case "status":
        state.selectedFilters.status.push({
          value: data.value,
          name: data.name
        })
        break
      case "role":
        state.selectedFilters.role.push({ value: data.value, name: data.name })
        break
      case "user":
        state.selectedFilters.user.push({ value: data.value, name: data.name })
        break
      case "group":
        state.selectedFilters.group.push({
          value: data.value,
          name: data.name
        })
        break
    }
  },
  deleteFilter (state, data) {
    switch (data.type) {
      case "campaign":
        state.selectedFilters.campaign = state.selectedFilters.campaign.filter(
          row => row.value !== data.value
        )
        break
      case "tag":
        state.selectedFilters.tag = state.selectedFilters.tag.filter(
          row => row.value !== data.value
        )
        break
      case "brand":
        state.selectedFilters.brand = state.selectedFilters.brand.filter(
          row => row.value !== data.value
        )
        break
      case "status":
        state.selectedFilters.status = state.selectedFilters.status.filter(
          row => row.value !== data.value
        )
        break
      case "role":
        state.selectedFilters.role = state.selectedFilters.role.filter(
          row => row.value !== data.value
        )
        break
      case "user":
        state.selectedFilters.user = state.selectedFilters.user.filter(
          row => row.value.uuid !== data.value.uuid
        )
        break
      case "group":
        state.selectedFilters.group = state.selectedFilters.group.filter(
          row => row.value.uuid !== data.value.uuid
        )
        break
    }
  },
  clearFilters (state) {
    state.selectedFilters.campaign = []
    state.selectedFilters.tag = []
    state.selectedFilters.brand = []
    state.selectedFilters.status = []
    state.selectedFilters.role = []
    state.selectedFilters.user = []
    state.selectedFilters.group = []
  },
  setNotifications (state, data) {
    state.notifications = data
  },
  addNotification (state, notification) {
    state.notifications.unshift(notification)
  },
  selectTag (state, tag) {
    state.selectedTag = tag
  },
  setAdTags (state, tags) {
    state.adtags = tags
  },
  setSelectedDashboard (state, dashboard) {
    state.selectedDashboard = dashboard
  },
  setSelectedShowroomSettingsEditorMode (state, dashboard) {
    state.selectedShowroomSettingsEditorMode = dashboard
  },
  renameActive (state, value) {
    state.renamingInputActive = value
  },
  clearDesignerState (state) {
    undoRedoHistory.clear()
    state.currentDirectoryLevel = "/"
    state.currentAssetsTree = {}
    state.cropImgData = {}
    state.loadingStatus = false
    state.renamingInputActive = false
    state.settingsObject = {}
    state.settingsContext = ""
    state.lastSavedStateHash = ""
    state.firstStateSaved = false
    state.currentState = null
    state.stateId = null
    state.draggedAsset = null
    state.draggedModule = null
    state.dataBinding = []
    state.draftEvent = null
    state.locked = false
    state.recordingTime = -1
    state.recordingModule = null
    state.lockedBy = {}
    state.events = []
    state.scenes = []
    state.modules = []
    state.assets = []
    state.guidelines = {
      horizontal: [],
      vertical: [],
      horizontalLocked: [],
      verticalLocked: []
    }
    state.activeScene = ""
    state.activeTimelineBlock = null
    state.activeTimelineBlocks = []
    state.activeTimelineTab = null
    state.packageId = ""
    state.currentTime = 0
    state.selectedModule = null
    state.currentScope = null
    state.bgMarkVisibility = false
    state.savedColors = []
    state.collapsedLayers = []
  },
  collapseLayer (state, id) {
    const idx = state.collapsedLayers.indexOf(id)
    if (idx !== -1) {
      state.collapsedLayers.splice(idx, 1)
    } else {
      state.collapsedLayers.push(id)
    }
  },
  collapseTimelineLayer (state, id) {
    const idx = state.collapsedTimelineLayers.indexOf(id)
    if (idx !== -1) {
      state.collapsedTimelineLayers.splice(idx, 1)
    } else {
      state.collapsedTimelineLayers.push(id)
    }
  },
  setNavigationGuardSkip (state, value) {
    state.skipNavigationGuard = value
  },
  incrementLayerFolderIndex (state) {
    state.layerFolderIndex++
  },
  incrementLayerInputCalls (state) {
    state.layerInputCalls++
  },
  addDataBinding (state, dataBindingEntry) {
    const filteredDataBinding = state.dataBinding.filter((d) => {
      if (d.uuid === dataBindingEntry.uuid) return false
      const destinationModules = Utils.getAllModules(state.scenes).filter(m => m.type === DestinationModuleType)
      return destinationModules.some(m => Utils.getModuleDataValue(m, "dataBindingId", null) === d.uuid)
    })

    state.dataBinding = [
      ...filteredDataBinding,
      dataBindingEntry
    ]
  },
  removeDataBinding (state, dataBindingEntryUuid) {
    state.dataBinding = state.dataBinding.filter(
      d => d && d.uuid !== dataBindingEntryUuid
    )
  },
  clearDataBinding (state) {
    state.dataBinding = []
  },
  setCompanyTags (state, tags) {
    state.companyTags = tags
  },
  addCompanyTag (state, tagName) {
    if (state.companyTags.indexOf(tagName) === -1) {
      state.companyTags.push(tagName)
    }
  },
  setPreviewDataBinding (state, value) {
    state.previewDataBinding = value
  },
  swapAsset (state, { oldAsset, newAsset }) {
    state.scenes = Utils.swapFileValue(state.scenes, oldAsset, newAsset)
  },
  addAssetToActiveDesign (state, asset) {
    state.assets = [...state.assets, asset]
  },
  setDownloadLinks (state, links) {
    state.activeDesign.download_links = links
  },
  setActiveTimelineTab (state, tabId) {
    this.commit("setEditingCustomAnimation", null)
    this.commit("setActiveTimelineBlocks", [])
    this.commit("setActiveTimelineBlock", null)
    state.activeTimelineTab = tabId
  },
  setBlockScreenshot (state, isBlocked) {
    state.blockScreenshot = isBlocked
  },
  setUserWorkspaceSettings (state, settings) {
    if (state.currentUser) {
      state.currentUser.settings = Object.assign({}, state.currentUser ? state.currentUser.settings : {}, settings)
    }
  },
  setDeletedAssets (state, assets) {
    state.deletedAssetsId = assets
  },
  setTimelineScaleX (state, val) {
    state.timelineScaleX = val
  },
  setTimelineScaleY (state, val) {
    state.timelineScaleY = val
  },
  setTimelineOffset (state, val) {
    state.timelineOffset = val
  },
  setTimelineSettingsCollapsed (state, val) {
    state.timelineSettingsCollapsed = val
  },
  setDesignerZoom (state, val) {
    state.designerZoom = val
  },
  toggleTimelineSettingsCollapsed (state) {
    state.timelineSettingsCollapsed = !state.timelineSettingsCollapsed
  },
  updateDesignFields (state, { designUuid, newFields }) {
    const design = state.designs.find(design => design.uuid === designUuid)
    if (design) {
      Object.entries(newFields).forEach(field => {
        design[field[0]] = field[1]
      })
    }
  },
  setAssetUploadCallback (state, cb) {
    state.assetUploadCallback = cb
  },

  // VARIANTS
  synchronizeScenesBetweenVariants (state) {
    state.activeDesign.variants = VariantManager.synchronizeScenesBetweenVariants(state.activeDesign.variants)
  },
  synchronizeModulesBetweenVariants (state) {
    state.activeDesign.variants = VariantManager.synchronizeModulesBetweenVariants(state.activeDesign.variants, state.assets)
  },
  synchronizeBaseVariantWithDeviceDimensions (state) {
    state.activeDesign.variants[0] = VariantManager.updateVariantDimensionsToMatchDevice(state.activeDesign.baseVariantDimensions, state.activeDesign.variants.find(v=>v.active === true) || state.activeDesign.variants[0], state.assets)
    state.activeDesign.variants = state.activeDesign.variants.filter((v, idx)=> idx === 0)
    state.activeDesign.variants[0].active = true
    this.commit("setScenes", state.activeDesign.variants[0].scenes)
    this.commit("setActiveScene", state.activeDesign.variants[0].scenes[0].uuid)
  },
  updateActiveVariantDeviceOrientationLock (state, val) {
    const activeVariant = state.activeDesign.variants.find(v => v.active === true)
    if (activeVariant) {
      activeVariant.deviceOrientationLock = val
    }
  },
  updateActiveVariantDeviceDimensionsLock (state, val) {
    const activeVariant = state.activeDesign.variants.find(v => v.active === true)
    if (activeVariant) {
      activeVariant.deviceDimensionsLock = val
    }
  },
  updateActiveVariantDeviceOrientation (state, val) {
    const activeVariant = state.activeDesign.variants.find(v => v.active === true)
    if (activeVariant) {
      activeVariant.deviceOrientation = val
    }
  },
  updateUseAspectRatios (state, val) {
    state.activeDesign.useAspectRatios = val
  },
  updateUseInterscrollerPreview (state, val) {
    state.activeDesign.useInterscrollerPreview = val
  },
  setVariants (state, variants) {
    state.activeDesign.variants = variants
  },
  synchronizeVariants (state) {
    state.activeDesign.variants = VariantManager.synchronizeVariants(state.activeDesign.variants, state.assets, state.scenes)
  },
  deactivateAllVariants (state) {
    state.activeDesign.variants.forEach(v => (v.active = false))
  },
  activateVariant (state, { selectedVariant }) {
    state.activeDesign.variants.find(
      v =>
        v.dimensions[0] === selectedVariant.dimensions[0] &&
        v.dimensions[1] === selectedVariant.dimensions[1]
    ).active = true
  },
  removeVariant (state, variant) {
    const activeVariant = state.activeDesign.variants.find(v => v.active === true)

    state.activeDesign.variants = state.activeDesign.variants.filter(
      v => !(v.dimensions[0] === variant.dimensions[0] && v.dimensions[1] === variant.dimensions[1])
    )

    if (variant.dimensions[0] === activeVariant.dimensions[0] && variant.dimensions[1] === activeVariant.dimensions[1]) {
      this.commit("deactivateAllVariants")
      state.activeDesign.variants[0].active = true
      this.commit("setScenes", state.activeDesign.variants[0].scenes)
    }
  },
  addDesignVariant (state, variant) {
    const newVariant = VariantManager.getNewVariant({
      newVariant: variant,
      variants: state.activeDesign.variants,
      assets: state.assets
    })

    state.activeDesign.variants.push(newVariant)

    if (Utils.hasDifferentOrientationsVariants(state.activeDesign.variants)) {
      state.activeDesign.useAspectRatios = false
    }

    if (state.events.length > 0) {
      state.events = [...state.events, ...VariantManager.getNewVariantEvents({
        events: Utils.cloneDeep(state.events),
        variant: newVariant,
        variants: state.activeDesign.variants
      })]
    }

    this.commit("setScenes", newVariant.scenes)
  },

  setAccessedDomain (state, domain) {
    state.activeDomain = domain
    state.domainLimitedAccess = true
  },

  setBuildNodeStatus (state, status) {
    state.buildNodeStatus = status
  }
}

const getInitEvents = (module) => {
  let result = []
  switch (module.type) {
    case TypoModuleType:
      result = [{
        trigger: EventManager.TRIGGER_CLICK,
        target: module.uuid,
        action: {
          [EventManager.ACTION_TRACK]: {
            trackingUrl: "clickout"
          }
        }
      }]
  }

  return result.map((res) => {
    res.uuid = uuidv4()
    return res
  })
}
